import React, { Component } from "react";
import ConferenceContainer from "../../../container/Halle/Konferenzraeume/Conference";
import axios from "axios";
import AppURL from "../../../api/AppUrl";
import PageSlider from '../../../components/PageSlider'

class Conference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      KonferenzSliderData: [],
      hallId: this.props.hallId,
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.hallId ? nextProps.hallId : 0) === (this.props.hallId ? this.props.hallId : 0)
  }
  componentDidMount() {
    axios
      .get(AppURL.KonferenzSlider + '/' + this.props.hallId)
      .then((response) => {
        this.setState({ KonferenzSliderData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div>
        <ConferenceContainer formType="formConference" hallId={this.props.hallId} />
      </div>
    );
  }
}

export default Conference;
