const CardButton = ({ label, fullWidth, type, clickHandler = () => {}, customClasses = '' }) => {
  return (
    <button
      className={`rounded-lg bg-primary-2 py-1 ${fullWidth ? 'w-full' : 'px-4'} text-white hover:bg-primary-2 font-bold ${customClasses}`}
      type={type ?? 'button'}
      onClick={clickHandler}
    >
      {label}
    </button>
  )
}
export default CardButton
