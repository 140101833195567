import image_1 from "../../assets/Kapazitaeten/KO-Übersicht(2).png";
import image_2 from "../../assets/Kapazitaeten/KO-Übersicht(3).png";
import image_3 from "../../assets/Kapazitaeten/KO-Übersicht(4).png";
import image_4 from "../../assets/Kapazitaeten/KO-Übersicht(5).png";
import image_5 from "../../assets/Kapazitaeten/KO-Übersicht(6).png";
import image_6 from "../../assets/Kapazitaeten/KO1-Block.png";
import image_7 from "../../assets/Kapazitaeten/KO1-Parlament.png";
import image_8 from "../../assets/Kapazitaeten/KO1-Parlamentschmal.png";
import image_9 from "../../assets/Kapazitaeten/KO1-Reihe.png";
import image_10 from "../../assets/Kapazitaeten/KO1-U-Form.png";
import image_11 from "../../assets/Kapazitaeten/KO2-Block.png";
import image_12 from "../../assets/Kapazitaeten/KO2-Parlament.png";
import image_13 from "../../assets/Kapazitaeten/KO2-Parlamentschmal.png";
import image_14 from "../../assets/Kapazitaeten/KO2-Reihe.png";
import image_15 from "../../assets/Kapazitaeten/KO2-U-Form.png";
import image_16 from "../../assets/Kapazitaeten/KO3-Block.png";
import image_17 from "../../assets/Kapazitaeten/KO3-Parlament.png";
import image_18 from "../../assets/Kapazitaeten/KO3-Parlamentschmal.png";
import image_19 from "../../assets/Kapazitaeten/KO3-Reihe.png";
import image_20 from "../../assets/Kapazitaeten/KO3-U-Form.png";
import image_21 from "../../assets/Kapazitaeten/KO4-Block.png";
import image_22 from "../../assets/Kapazitaeten/KO4-Parlament.png";
import image_23 from "../../assets/Kapazitaeten/KO4-Parlamentschmal.png";
import image_24 from "../../assets/Kapazitaeten/KO4-Reihe.png";
import image_25 from "../../assets/Kapazitaeten/KO4-U-Form.png";
import image_26 from "../../assets/Kapazitaeten/KO5-Block.png";
import image_27 from "../../assets/Kapazitaeten/KO5-Parlament.png";
import image_28 from "../../assets/Kapazitaeten/KO5-Parlamentschmal.png";
import image_29 from "../../assets/Kapazitaeten/KO5-Reihe.png";
import image_30 from "../../assets/Kapazitaeten/KO5-U-Form.png";
import image_31 from "../../assets/Kapazitaeten/KO6-Block.png";
import image_32 from "../../assets/Kapazitaeten/KO6-Parlament.png";
import image_33 from "../../assets/Kapazitaeten/KO6-Parlamentschmal.png";
import image_34 from "../../assets/Kapazitaeten/KO6-Reihe.png";
import image_35 from "../../assets/Kapazitaeten/KO6-U-Form.png";
import image_36 from "../../assets/Kapazitaeten/KO7-Block.png";
import image_37 from "../../assets/Kapazitaeten/KO7-Parlament.png";
import image_38 from "../../assets/Kapazitaeten/KO7-Parlamentschmal.png";
import image_39 from "../../assets/Kapazitaeten/KO7-Reihe.png";
import image_40 from "../../assets/Kapazitaeten/KO7-U-Form.png";




const hallImages = {
    0: [image_5, image_1, image_2, image_3, image_4],
    1: [image_6, image_7, image_8, image_9, image_10],
    2: [image_11, image_12, image_13, image_14, image_15],
    3: [image_16, image_17, image_18, image_19, image_20],
    4: [image_21, image_22, image_23, image_24, image_25],
    5: [image_26, image_27, image_28, image_29, image_30],
    6: [image_31, image_32, image_33, image_34, image_35],
    7: [image_36, image_37, image_38, image_39, image_40],
  };


function Imagecards({ hallId }) {
    const images = hallImages[hallId] || [];

    return (
      <div className="grid grid-cols-5 gap-4 mt-0 mb-6">
        {images.map((imageSrc, index) => (
          <img 
            key={index} 
            src={imageSrc} 
            alt={`Description ${index + 1}`} 
            className="w-full h-auto shadow-md rounded bg-white"
          />
        ))}
      </div>
    );
  }

  export default Imagecards;