import React, { Component } from "react";
import "./Events.scss";
import EventsContainer from "../../container/Events/Events";
import axios from "axios";
import AppURL from "../../api/AppUrl"
import PageSlider from '../../components/PageSlider'

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VeranstaltungenSliderData: [],
    }
  }

  componentDidMount() {
    axios.get(AppURL.VeranstaltungenSlider)
    .then(response => {
      this.setState({ VeranstaltungenSliderData: response.data });
    }) 
    .catch(error => {
      console.log(error);
    });
  }
  render() {
    return (
      <div>
        <PageSlider data={this.state.VeranstaltungenSliderData} />
        <a></a>
        <EventsContainer></EventsContainer>
      </div>
    );
  }
}

export default Events;
