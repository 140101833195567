import React from "react";
import "./Events.scss";
import  EventsContainer  from "../../container/Events/SingleEvent";
import backgroundImage from "../../assets/MSH_GroßerSaalKonzert_4.JPG";

const Events = () => {
  return (
    <>
        <section
          className="text-white py-40 lg:h-1/2 lg:items-center bg-cover bg-center"
          style={{
            backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.1), rgba(128, 128, 128, 0.3)), url(${backgroundImage})`,
          }}
        ></section>
        <a></a>
      <EventsContainer></EventsContainer>

    </>
  );
};

export default Events;
