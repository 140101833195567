import React from 'react'
import { Link } from 'react-router-dom'
import CardButton from './CardButton'

const NewsCard = ({ item }) => {
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  }

  return (
    <div className="flex flex-col h-full bg-white rounded-xl shadow-lg overflow-hidden">
      <img
        className="w-full object-cover object-center"
        src={item.news_image}
        alt="news"
      />
      <div className="flex flex-col px-6 h-auto pt-3">
        <h2 className="event--titel font-bold text-large mb-5 text-gray-700 text-center">
          {item.news_category}
        </h2>
        <h1 className="text-center title-font text-lg font-medium text-gray-900 mb-3">
          {item.news_title}
        </h1>
        <p className="leading-relaxed mb-3 text-center">
          {truncateText(item.news_text, 100)}
        </p>
      </div>
      <div className="flex-grow" />
      <div className="text-center py-4">
        <Link
          to={{
            pathname: `/news/${item.news_code}`,
            state: { newsItem: item },
          }}
        >
          <CardButton label="Weiterlesen" type="button" />
        </Link>
      </div>
    </div>
  )
}

export default NewsCard
