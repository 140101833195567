import React, { Component } from "react";
import "./GroßerSaal.scss";
import AppURL from "../../../api/AppUrl";
import axios from "axios";
import MSH_Außenansicht_2 from "../../../assets/MSH_Außenansicht_2.jpg";
import Imagecards from "../../../components/imagecards/imagecards";
import ImageSliderWithText from "../../../components/HallSmallSlider/ImageSliderWithTextLeft";
import DownloadSection from "../../../components/DownloadSection";
import Buehnenbeleuchtung from "../../../assets/PDFFiles/Großer Saal/buhnenbeleuchtung_grosser_saal.pdf";
import SaalUebersicht from "../../../assets/PDFFiles/Großer Saal/grosser_saal_uebersicht.pdf";
import FoyerStromAnschluesse from "../../../assets/PDFFiles/Großer Saal/grosserfoyer_stromanschluesse.pdf";
import SaalStromAnschluesse from "../../../assets/PDFFiles/Großer Saal/grossersaal_stromanschluesse.pdf";
import HaengePunkte from "../../../assets/PDFFiles/Großer Saal/grossersaal_haengepunkte.pdf";
import FoyerUebersicht from "../../../assets/PDFFiles/Großer Saal/grossesfoyer_uebersicht.pdf";
import Kapazitaeten from "../../../assets/PDFFiles/Großer Saal/kapazitaet_cd.pdf";
import VenueInformation from "../../../assets/PDFFiles/Großer Saal/venue_sheet_msh_grosser_bereich.pdf";
import InfoColumns from "../../../components/InfoColumns/InfoColumns";
import image_1 from "../../../assets/Kapazitaeten/GS-Bankett1.png";
import image_2 from "../../../assets/Kapazitaeten/GS-Bankett2.png";
import image_3 from "../../../assets/Kapazitaeten/GS-Parlament.png";
import image_4 from "../../../assets/Kapazitaeten/GS-Parlamentschmal.png";
import image_5 from "../../../assets/Kapazitaeten/GS-Reihe.png";
import CardButton from "../../../components/CardButton";
import HighlightParagraph from '../../../components/Texts/HighlightParagraph'
import HeadingParagraph from '../../../components/Texts/HeadingParagraph'
import Paragraph from '../../../components/Texts/Paragraph'
import ContactSection from '../../../components/ContactSection'

const downloadSectionConfig = {
  heading: "Weitere Informationen zum Herunterladen",
  links: [
    {
      url: Buehnenbeleuchtung,
      text: "Bühnenbeleuchtung",
      fileName: "MSH - Großer Saal - Bühnenbeleuchtung",
    },
    {
      url: SaalUebersicht,
      text: "Saal Übersicht",
      fileName: "MSH - Großer Saal - Saal Übersicht",
    },
    {
      url: SaalStromAnschluesse,
      text: "Saal Stromanschlüsse",
      fileName: "MSH - Großer Saal - Saal Stromanschlüsse",
    },

    {
      url: FoyerUebersicht,
      text: "Foyer Übersicht",
      fileName: "MSH - Großer Saal - Foyer Übersicht",
    },
    {
      url: FoyerStromAnschluesse,
      text: "Foyer Stromanschlüsse",
      fileName: "MSH - Großer Saal - Foyer Stromanschlüsse",
    },
    {
      url: Kapazitaeten,
      text: "Kapazitäten",
      fileName: "MSH - Großer Saal - Kapazitäten",
    },
    {
      url: VenueInformation,
      text: "Venue Information",
      fileName: "MSH - Großer Saal - Venue Information",
    },
    {
      url: HaengePunkte,
      text: "Hängepunkte",
      fileName: "MSH - Großer Saal - Hängepunkte",
    },
  ],
};

class GroßerSaal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: "",
        email: "",
        company: "",
        subject: "",
        message: "",
      },
      isLoading: false,
      InfoData: [],
      CardsData: [],
      SmallSliderData: [],
      GoodToKnowData: [],
    };
  }

  componentDidMount() {
    const storedFormData = JSON.parse(localStorage.getItem("formInformation"));
    if (storedFormData) {
      this.setState({ formData: storedFormData });
    }
    axios
      .get(AppURL.SmallSlider("großersaal"))
      .then((response) => {
        this.setState({ SmallSliderData: response.data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(AppURL.GoodToKnow("großersaal"))
      .then((response) => {
        this.setState({ GoodToKnowData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(AppURL.GroßerInfo)
      .then((response) => {
        this.setState({ InfoData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(AppURL.GroßerCards)
      .then((response) => {
        this.setState({ CardsData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();

    // Get form data from state
    const { name, email, company, subject, message } = this.state.formData;

    // Get data from localStorage
    const localStorageData = localStorage.getItem("formInformation") ?? "{}"; // Replace 'myData' with the key for your stored data

    // Create the request body
    const requestBody = {
      formType: "big",
      name,
      email,
      company,
      subject,
      message,
      localStorageData, // Include the localStorage data in the request body
    };

    // Send the form data to the backend
    fetch("/submit-form-hall", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Reset the form and clear localStorage data if needed
        this.setState({
          formData: {
            name: "",
            email: "",
            company: "",
            subject: "",
            message: "",
          },
        });
        localStorage.removeItem("formInformation"); // Replace 'myData' with the key for your stored data
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  };

  render() {
    const { formData, InfoData, CardsData } = this.state;
    const images = [image_1, image_2, image_3, image_4, image_5];

    return (
      <div>
        <section className="bg-white py-8">
          <div className="container px-6 mx-auto">
            {/* InfoData Section */}
            <div className="px-6">
              {InfoData &&
                InfoData.map((data) => (
                  <div key={data.id} className="flex flex-col space-y-4">
                    <HighlightParagraph text={data.headline} />
                    <HeadingParagraph text={data.subtitle} />
                    <Paragraph text={data.paragraph} />
                  </div>
                ))}
            </div>
            {/* ImageSliderWithText Section */}
            <div className="px-6 bg-white">
              {this.state.isLoading ? (
                <p>Lade...</p>
              ) : (
                <ImageSliderWithText
                  slides={(this.state.SmallSliderData ?? []).map((item) => ({
                    image: item.slider_image,
                    text: item.slider_paragraph,
                  }))}
                  className="mt-6"
                />
              )}
            </div>

            <div className="mt-12 py-8 bg-stone-300 shadow-lg rounded-lg flex flex-col space-y-8">
              <HeadingParagraph text="Gut zu wissen"/>
              <InfoColumns data={this.state.GoodToKnowData} />
            </div>
          </div>
        </section>

        <section className="py-8 bg-gray-100">
          <div className="flex flex-col space-y-8 mx-8">
            <HeadingParagraph text="Kapazitäten" />
            <Imagecards images={images} className="mx-4" />
          </div>
          <div className="px-6 rounded-lg mt-8">
            <div className="grid grid-cols-2 lg:grid-cols-3">
              {CardsData &&
                CardsData.map((card, index) => (
                  <div className="flex flex-wrap w-full" key={index}>
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block object-cover object-center w-full h-full rounded-lg"
                        src={card.image}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>

        <section className="py-8 bg-white">
          <ContactSection
            salutation="Ansprechpartner"
            contactPersonName="Benedikt Schuster"
            contactPersonDescription="Veranstaltungen im Großen Saal & Foyer"
            contactPersonPhoneNumber="0911 / 231 - 80 13"
            contactPersonEmail="benedikt.schuster@stadt-nuernberg.de"
            imageSrc={MSH_Außenansicht_2}
            formData={formData}
            submitFunction={this.handleSubmit}
            changeFunction={this.handleChange}
          />
        </section>

        <section className="py-8 bg-gray-100">
          <DownloadSection config={downloadSectionConfig} />
        </section>
      </div>
    );
  }
}

export default GroßerSaal;
