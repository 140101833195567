import React from "react"
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'


const Pagination = ({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  maxPages,
}) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i)
  }

  function pageRange(page, pageCount, buffer) {
    var start = page - buffer,
      end = page + buffer

    if (end > pageCount) {
      start -= end - pageCount
      end = pageCount
    }
    if (start <= 0) {
      end += (start - 1) * -1
      start = 1
    }

    end = end > pageCount ? pageCount : end
    return {start: start, end: end}
  }

  return (
    <nav>
      <div className="grid grid-rows-1 grid-flow-col content-center max-w-3xl grow text-black">
        <div className="flex grid content-center justify-center">
          <button
            onClick={() => (currentPage > 1 ? paginate(currentPage - 1) : {})}
          >
            <FaArrowLeft className="mr-2 hover:scale-125 duration-150 ease-in-out" />{" "}
          </button>
        </div>

        {pageNumbers.map((number) => (
          <div key={number} className="">
            {(number >= pageRange(currentPage, pageNumbers.length, 3).start &&
              number <= pageRange(currentPage, pageNumbers.length, 3).end) ||
            number === 1 ||
            number === pageNumbers.length ? (
              <>
                {number === currentPage ? (
                  <button
                    onClick={() => paginate(number)}
                    className="font-semibold text-center my-3 mx-2 bg-primary-2 text-white w-6 rounded"
                  >
                    {number}
                  </button>
                ) : (
                  <>
                    {(number ===
                      pageRange(currentPage, pageNumbers.length, 3).start &&
                      number !== 1) ||
                    (number ===
                      pageRange(currentPage, pageNumbers.length, 3).end &&
                      number !== pageNumbers.length) ? (
                      <>
                        <div className="text-left font-semibold text-black my-3 mx-2">
                          ...
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => paginate(number)}
                          className="text-left font-semibold text-black my-3 mx-2"
                        >
                          {number}
                        </button>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
        <div className="flex grid content-center justify-center">
          <button
            onClick={() =>
              currentPage < pageNumbers.length ? paginate(currentPage + 1) : {}
            }
          >
            <FaArrowRight class="" />
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Pagination
