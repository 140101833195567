class AppURL {
  static BaseURL = "https://admin.meistersingerhalle.de:443/api" //change on Release
  // static BaseURL = "http://localhost:8000/api"; //change on Release
  static VisitorDetails = this.BaseURL + "/getvisitor";
  static ContactFormSend = this.BaseURL + "/contact";
  static LandingPageInfo = this.BaseURL + "/landingpageinfo";
  static LandingPageSlider = this.BaseURL + "/landingslider";
  static HistoriePageInfo = this.BaseURL + "/historie";
  static HervorgehobenVer = this.BaseURL + "/hervorgehoben";
  static HistorieSlider = this.BaseURL + "/historieslider";
  static VeranstaltungenSlider = this.BaseURL + "/veranstaltungenslider";
  static BesucherSlider = this.BaseURL + "/besucherslider";
  static VeranstalterSlider = this.BaseURL + "/veranstalterslider";
  static HallenSlider = this.BaseURL + "/hallenslider";
  static GroßerSaalSlider = this.BaseURL + "/großersaalslider";
  static KleinerSaalSlider = this.BaseURL + "/kleinersaalslider";
  static KonferenzSlider = this.BaseURL + "/konferenzslider";
  static OrgelSlider = this.BaseURL + "/orgelslider";
  static KontaktSlider = this.BaseURL + "/kontaktslider";
  static ContactInfo(id) {
    return this.BaseURL + '/contact/' + id;
  }
  static NewsList = this.BaseURL + "/news";
  static NewsListByCategory(news_category) {
    return this.BaseURL + "/newscategory/" + news_category;
  }
  static BesucherCards = this.BaseURL + "/besuchercards";
  static VeranstalterText = this.BaseURL + "/veranstaltertext";
  static VeranstalterCards = this.BaseURL + "/veranstaltercards";
  static Fragenkatalog = this.BaseURL + "/fragenkatalog";
  static HalleInfo = this.BaseURL + "/halleget";
  static HallePDF = this.BaseURL + "/hallepdfget";
  static GroßerInfo = this.BaseURL + "/großersaalget";
  static GroßerCards = this.BaseURL + "/großercards";
  static KleinerInfo = this.BaseURL + "/kleinersaalget";
  static KleinerCards = this.BaseURL + "/kleinercards";
  static KonferenzInfo = this.BaseURL + "/konferenzget";
  static KonferenzCards = this.BaseURL + "/konferenzcards";
  static OrgelInfo = this.BaseURL + "/orgelinfo";
  static OrgelCards = this.BaseURL + "/orgelcards";
  static TeamInfo = this.BaseURL + "/teaminfo";
  static VerticalTimeline = this.BaseURL + "/historie-timeline";
  static HistorieCTA = this.BaseURL + "/historiecta";
  
  static NewsDetails(news_code) {
    return this.BaseURL + "/news/" + news_code;
  }

  static SmallSlider(pageCode) {
    return this.BaseURL + '/smallslider/' + pageCode;
  }

  static GoodToKnow(pageCode) {
    return this.BaseURL + '/good-to-know/' + pageCode;
  }

}

export default AppURL;
