import React, { useEffect, useRef, Component } from "react";
import { FaCar, FaTrain, FaPlane } from "react-icons/fa";
import AutoAnreise from "../../assets/PDFFiles/Anfahrt/anfahrt_corpdes.pdf";
import backgroundImage from "../../assets/MSH_Außenansicht_4.jpg";
import Anfahrtsskizze from "../../assets/Anfahrtsskizze.png";
import AnreiseCard from '../../components/AnreiseCard'
class FindUs extends Component {
  render() {
    return (
      <div className="sm:container mx-auto">
        <section
          className="text-white py-40 lg:h-1/2 lg:items-center bg-cover bg-center"
          style={{
            backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.1), rgba(128, 128, 128, 0.3)), url(${backgroundImage})`,
          }}
        >
        </section>
        <section className="grid grid-cols-2 gap-4 p-4">
          <div className="max-w-3xl space-y-4">
            <div className="space-y-2">
              <h2 className="text-2xl font-bold mb-4 text-center">So finden Sie uns</h2>

              <p className="">
                Die Meistersingerhalle liegt mitten im Herzen Nürnbergs und ist
                trotzdem mit jedem Verkehrsmittel einfach und schnell zu
                erreichen.
              </p>

              <p className="">
                Damit Sie sich leichter orientieren können und ohne Probleme den
                Weg zu uns finden, haben wir Kartenmaterial, Wegbeschreibungen
                und Linienpläne zusammengestellt.
              </p>
            </div>


            <div className="space-y-2">
              <h3 className="text-xl font-semibold text-center">
                Anreise im Nahverkehr
              </h3>

              <p className="inline-block">
                Wenn Sie mit den öffentlichen Verkehrsmitteln zu uns kommen
                möchten, klicken Sie bitte einfach auf "öffentliche
                Verkehrsmittel". Sie werden weitergeleitet auf den Fahrplan
                der VGN. Hier müssen Sie lediglich Ihre Abfahrt-Haltestelle
                eingeben und erhalten sofort Ihren personalisierten Fahrplan.
              </p>
            </div>


            <div className="space-y-2">
              <h3 className="text-xl font-semibold text-center">
                Anreise von außerhalb
              </h3>

              <div>
                <div className="text-center space-x-2">
                  <FaCar className="inline-block  text-red-500" />
                  <a
                    href={AutoAnreise}
                    target="_blank"
                    rel="noreferrer"
                    className="text-red-500 hover:underline inline-block"
                  >
                    Mit dem Auto
                  </a>
                </div>

                <div className="text-center space-x-2">
                  <FaTrain className="inline-block text-red-500" />
                  <a
                    href="https://www.bahn.de"
                    target="_blank"
                    rel="noreferrer"
                    className="text-red-500 hover:underline inline-block"
                  >
                    Mit der Bahn
                  </a>
                </div>

                <div className="text-center space-x-2">
                  <FaPlane className="inline-block text-red-500" />
                  <a
                    href="https://www.airport-nuernberg.de"
                    target="_blank"
                    rel="noreferrer"
                    className="text-red-500 hover:underline inline-block"
                  >
                    Mit dem Flugzeug
                  </a>
                </div>
              </div>

            </div>
          </div>

          <div className="grid grid-rows-3 grid-cols-1 gap-8">
            <AnreiseCard
              headline="Meistersingerhalle Nürnberg - Großer Saal"
              street="Schultheißallee 2-4"
              city="90478 Nürnberg"
              vgn="https://www.vgn.de/verbindungen/?place_destination=Nürnberg&name_destination=internet%20&type_destination=any&anyObjFilter_destination=12&execInst=verifyOnly&sessionID=0"
              geoportal="https://geoportal.nuernberg.de/masterportal/stadtplan/?featureviaurl=%5B%7B%22layerId%22%3A%22urlPointFeatures%22%2C%22features%22%3A%5B%7B%22coordinates%22%3A%5B652624.9649999999%2C5478254.218699999%5D%2C%22label%22%3A%22Schulthei%C3%9Fallee%202-4%22%7D%5D%7D%5D&center=652624.9649999999,5478254.218699999&zoomlevel=5"
            />
            <AnreiseCard
              headline="Meistersingerhalle Nürnberg - Kleiner Saal"
              street="Münchener Straße 19"
              city="90478 Nürnberg"
              vgn="https://www.vgn.de/verbindungen/?place_destination=N%C3%BCrnberg&name_destination=M%fcnchener%20Stra%dfe%2019&type_destination=any&anyObjFilter_destination=12&execInst=verifyOnly&sessionID=0"
              geoportal="https://geoportal.nuernberg.de/masterportal/stadtplan/?featureviaurl=%5B%7B%22layerId%22%3A%22urlPointFeatures%22%2C%22features%22%3A%5B%7B%22coordinates%22%3A%5B652536.0433%2C5478200.6504%5D%2C%22label%22%3A%22M%C3%BCnchener%20Stra%C3%9Fe%2019%22%7D%5D%7D%5D&center=652536.0433,5478200.6504&zoomlevel=5"
            />

            <AnreiseCard
              headline="Meistersingerhalle Nürnberg - Konferenzräume und Verwaltung"
              street="Münchener Straße 21"
              city="90478 Nürnberg"
              vgn="https://www.vgn.de/verbindungen/?place_destination=N%C3%BCrnberg&name_destination=M%fcnchener%20Stra%dfe%2021&type_destination=any&anyObjFilter_destination=12&execInst=verifyOnly&sessionID=0"
              geoportal="https://geoportal.nuernberg.de/masterportal/stadtplan/?featureviaurl=%5B%7B%22layerId%22%3A%22urlPointFeatures%22%2C%22features%22%3A%5B%7B%22coordinates%22%3A%5B652528.7669000002%2C5478182.3487%5D%2C%22label%22%3A%22M%C3%BCnchener%20Stra%C3%9Fe%2021%22%7D%5D%7D%5D&center=652528.7669000002,5478182.3487&zoomlevel=5"
            />
          </div>
        </section>

        <section className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-16 items-stretch mb-5">
          <div className="flex flex-col">
            <h3 className="text-xl font-semibold capitalize px-2">Lageplan</h3>
            <a
              href="https://www.google.com/maps"
              target="_blank"
              rel="noreferrer"
              className="text-red-500 hover:underline block mt-2 px-2"
            >
              Größere Karte anzeigen
            </a>
            <iframe
              className="flex-grow mt-4"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.4899751861135!2d11.102988115694966!3d49.4374556793484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479f577bed36c0b9%3A0x90f9afbd37fda516!2sMeistersingerhalle!5e0!3m2!1sde!2sde!4v1663244180080!5m2!1sde!2sde"
              style={{ width: "100%", height: "230px" }}
            ></iframe>{" "}
          </div>
          {/* Second Column */}
          <div className="flex flex-col">
            <h3 className="text-xl font-semibold capitalize px-2">
              Anfahrt mit der VGN
            </h3>
            <a
              href="https://www.vgn.de"
              target="_blank"
              rel="noreferrer"
              className="text-red-500 hover:underline block mt-2 px-2"
            >
              Zur Webseite der VGN
            </a>
            <iframe
              className="flex-grow mt-0"
              src={`${process.env.PUBLIC_URL}/vgn-widget.html`}
              title="VGN Widget"
              frameBorder="0"
            ></iframe>
          </div>
          {/* Third Column */}
          <div className="flex flex-col">
            <h3 className="text-xl font-semibold capitalize px-2">
              Parkmöglichkeiten
            </h3>
            <a
              href={AutoAnreise}
              target="_blank"
              rel="noreferrer"
              className="text-red-500 hover:underline block mt-2 px-2"
            >
              Download PDF
            </a>
            <img
              src={Anfahrtsskizze}
              alt="Anfahrtsskizze Meistersingerhalle Nürnberg"
              className="mt-4 w-full h-auto"
              style={{ width: "100%", height: "450px" }}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default FindUs;
