import React, { Component } from "react";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import Image from "../../assets/MSH_Außenansicht_Daemmerung.jpg";
import CardButton from '../../components/CardButton'
import ContactCard from '../../components/ContactCard'
import PageSlider from '../../components/PageSlider'

export class KontaktPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ContactInfoData: [],
      KontaktSliderData: [],
      TeamInfoData: [],
      formData: {
        name: "",
        email: "",
        telefonnummer: "",
        betreff: "",
        nachricht: "",
      },
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.KontaktSlider)
      .then((response) => {
        this.setState({ KontaktSliderData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(AppURL.ContactInfo(1))
      .then((response) => {
        this.setState({ ContactInfoData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(AppURL.TeamInfo)
      .then((response) => {
        this.setState({ TeamInfoData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { formData } = this.state;

    axios
      .post("/submit-form", formData)
      .then((response) => {
        // Handle the success response
        console.log(response.data);
      })
      .catch((error) => {
        // Handle the error response
        console.error(error);
      });
  };
  render() {
    const { TeamInfoData, formData } = this.state;

    return (
      <>
        <PageSlider data={this.state.KontaktSliderData} />
        <section className="py-8 text-center bg-gray">
          <div className="sm:container mx-auto">
            <h4 className="font-semibold text-2xl text-dark mb-8">
              Kontaktiere uns
            </h4>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              <div className="w-full h-full">
                <div className="flex flex-col h-full p-10 md:px-7 xl:px-10 rounded-none sm:rounded-xl bg-white shadow-md">
                  <div className="mb-2 text-blueGray-600">
                    <a href="tel:+499112318000" target="_blank" rel="noreferrer">
                      <i className="fa fa-phone mr-2 text-8xl" />
                    </a>
                  </div>
                  <h4 className="font-semibold text-xl text-dark mb-3">Telefon</h4>
                  <div className="flex-grow" />
                  <p className="text-body-color">
                    Telefon: {this.state.ContactInfoData.telefon} <br />{" "}
                    Telefax: {this.state.ContactInfoData.telefax}
                  </p>
                </div>
              </div>
              <div className="w-full h-full">
                <div className="flex flex-col h-full p-10 md:px-7 xl:px-10 rounded-none sm:rounded-xl bg-white shadow-md">
                  <div className="mb-2">
                    <a href="mailto:" target="_blank" rel="noreferrer">
                      <i className="fa fa-envelope mr-2 text-8xl" />
                    </a>
                  </div>
                  <h4 className="font-semibold text-xl text-dark mb-3">Email</h4>
                  <div className="flex-grow" />
                  <a href={`mailto:${this.state.ContactInfoData.email}`} className="text-body-color break-words">
                    {this.state.ContactInfoData.email}
                    <p></p> <br></br>
                  </a>
                </div>
              </div>
              <div className="w-full h-full">
                <div className="flex flex-col h-full p-10 md:px-7 xl:px-10 rounded-none sm:rounded-xl bg-white shadow-md">
                  <div className="mb-2 text-blueGray-600">
                    <a
                      href="https://www.google.de/maps/dir//Meistersingerhalle,+Münchener+Str.+21,+90478+Nürnberg/@49.4374557,11.1029828,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x479f577bed36c0b9:0x90f9afbd37fda516!2m2!1d11.1051768!2d49.4374557?hl=de"
                      target="_blank" rel="noreferrer"
                    >
                      <i className="fa fa-map-marker mr-2 text-8xl" />
                    </a>
                  </div>
                  <h4 className="font-semibold text-xl text-dark mb-3">Anfahrt</h4>

                  <p className="text-body-color">
                    {this.state.ContactInfoData.address &&
                      this.state.ContactInfoData.address
                        .split(",")
                        .map((part, index) => (
                          <span key={index}>
                            {part.trim()}
                            <br />
                          </span>
                        ))}
                  </p>
                  <div className="flex-grow" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white py-8">
          <div className="sm:container mx-auto">
            <h4 className="font-semibold text-2xl text-dark text-center mb-8">
              Unser Team
            </h4>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-center">
              {TeamInfoData.map((member, index) => (
                <ContactCard card={member} index={index} key={index} />
              ))}
            </div>
          </div>
        </section>
        <section className="py-8 bg-gray">
          <h4 className="text-center font-semibold text-2xl text-dark mb-8">
            Kontaktformular
          </h4>
          <div className="sm:container sm:mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Left Side */}
              <div className="w-full flex flex-col">
                <div className="bg-white rounded-none sm:rounded-xl shadow-md p-6 flex-1">
                  <div className="flex flex-col space-y-3">

                    <p className="text-lg">
                      Keine Lust auf ein Formular? Dann{" "}
                      <a
                        href="mailto:info@meistersingerhalle.de"
                        className="underline"
                        target="_blank" rel="noreferrer"
                      >
                        hier
                      </a>{" "}
                      klicken.
                    </p>
                    <img
                      src={Image}
                      alt=""
                      className="w-full rounded-none sm:rounded-xl shadow-lg"
                    />
                  </div>
                </div>
              </div>
              {/* Right Side */}
              <div className="w-full flex flex-col">
                <div className="bg-white rounded-none sm:rounded-xl shadow-md p-6 flex-1">
                  <form onSubmit={this.handleSubmit}>
                    {" "}
                    {/* Adjusted spacing between form elements */}
                    {/* Form fields */}
                    <div className="mb-4">
                      <input
                        className="border rounded-lg py-2 px-3 w-full text-left placeholder:text-center"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={this.handleChange}
                        placeholder="Name"
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        className="border rounded-lg py-2 px-3 w-full text-left placeholder:text-center"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={this.handleChange}
                        placeholder="Email Adresse"
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        className="border rounded-lg py-2 px-3 w-full text-left placeholder:text-center"
                        type="text"
                        name="telefonnummer"
                        value={formData.telefonnummer}
                        onChange={this.handleChange}
                        placeholder="Telefonnummer"
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        className="border rounded-lg py-2 px-3 w-full text-left placeholder:text-center"
                        type="text"
                        name="betreff"
                        value={formData.betreff}
                        onChange={this.handleChange}
                        placeholder="Betreff"
                      />
                    </div>
                    <div className="mb-4">
                      <textarea
                        className="border rounded-lg py-2 px-3 w-full text-left placeholder:text-center"
                        name="nachricht"
                        value={formData.nachricht}
                        onChange={this.handleChange}
                        rows="4"
                        placeholder="Nachricht"
                      ></textarea>
                    </div>
                    <CardButton label="Nachricht senden" type="submit" fullWidth={true} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.4899751861135!2d11.102988115694966!3d49.4374556793484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479f577bed36c0b9%3A0x90f9afbd37fda516!2sMeistersingerhalle!5e0!3m2!1sde!2sde!4v1663244180080!5m2!1sde!2sde"
          style={{ width: "100%", height: "600px" }}
        ></iframe>
      </>
    );
  }
}

export default KontaktPage;
