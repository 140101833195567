import React, { Component } from "react";import axios from "axios";
import AppURL from "../../api/AppUrl";
import HalleCards from "../../components/Cards/HalleCards";
import Degree from "../../components/360Degree/360Degree";
import PageSlider from '../../components/PageSlider'

class HallePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      HallenSliderData: [],
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.HallenSlider)
      .then((response) => {
        this.setState({ HallenSliderData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div>
        <PageSlider data={this.state.HallenSliderData} />
        <HalleCards />
        <div id="Degree">
          <Degree />
        </div>
      </div>
    );
  }
}

export default HallePage;
