import React, { createContext, useContext, useState } from 'react';

const TextToSpeechContext = createContext();

export const useTextToSpeech = () => useContext(TextToSpeechContext);

export const TextToSpeechProvider = ({ children }) => {
  const [text, setText] = useState('');

  const speakText = (textToSpeak) => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.speak(new SpeechSynthesisUtterance(textToSpeak));
    }
  };

  return (
    <TextToSpeechContext.Provider value={{ text, setText, speakText }}>
      {children}
    </TextToSpeechContext.Provider>
  );
};