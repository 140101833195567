import React, { Component } from "react";
import { images } from "../../constants";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import Card from "../../components/Card";
import HeadingParagraph from "../../components/Texts/HeadingParagraph";
class Informationen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BesucherCardsData: [],
    };
  }
  componentDidMount() {
    axios
      .get(AppURL.BesucherCards)
      .then((response) => {
        this.setState({ BesucherCardsData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const { BesucherCardsData } = this.state;
    return (
      <div className="text-center pb-8">
        <section className="text-center mt-8">
          <div className="flex flex-col mx-auto sm:container justify-center">
            <HeadingParagraph text="Besucherinformationen" />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 pt-8">
              {BesucherCardsData.map((card) => (
                <Card card={card} key={card.id} />
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Informationen;
