import React from 'react'
import CardButton from './CardButton'

const ContactSection = (
  {
    salutation,
    contactPersonName,
    contactPersonDescription,
    contactPersonPhoneNumber,
    contactPersonEmail,
    imageSrc,
    formData,
    submitFunction,
    changeFunction
  }) => {
  return (
    <div className="lg:flex lg:items-start container px-6 mx-auto">
      <div className="lg:w-1/2 pr-4">
        <h3 className="text-2xl font-semibold text-dark mb-4">
          { salutation }
        </h3>
        <div className="mb-6">
          <p className="text-lg font-semibold">{ contactPersonName }</p>
          <p className="text-base text-gray-600">
            { contactPersonDescription }
          </p>
          <p className="text-base text-gray-600">
            Telefon: { contactPersonPhoneNumber }
          </p>
          <div>
            <span className="text-base text-gray-600">Email: {" "}</span>
            <a href={`mailto:${contactPersonEmail}`} className="text-base text-gray-600 underline">
              { contactPersonEmail }
            </a>
          </div>
        </div>
        <img
          src={imageSrc}
          alt="MSH Außenansicht"
          className="w-full mt-4 rounded"
        />
      </div>

      {/* Right Side: Kontaktformular */}
      <div className="lg:w-1/2 p-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-4 mt-20">
          Kontaktformular
        </h3>
        <form onSubmit={submitFunction}>
          <div className="mb-4 space-y-2">
            <label className="block font-semibold text-gray-700">
              Name
            </label>
            <input
              className="border rounded-lg py-2 px-3 w-full"
              type="text"
              name="name"
              value={formData.name}
              onChange={changeFunction}
              placeholder="Name"
            />
          </div>
          <div className="mb-4 space-y-2">
            <label className="block font-semibold text-gray-700">
              Email Adresse
            </label>
            <input
              className="border rounded-lg py-2 px-3 w-full"
              type="email"
              name="email"
              value={formData.email}
              onChange={changeFunction}
              placeholder="Email Adresse"
            />
          </div>
          <div className="mb-4 space-y-2">
            <label className="block font-semibold text-gray-700">
              Firma
            </label>
            <input
              className="border rounded-lg py-2 px-3 w-full"
              type="text"
              name="company"
              value={formData.company}
              onChange={changeFunction}
              placeholder="Firma"
            />
          </div>
          <div className="mb-4 space-y-2">
            <label className="block font-semibold text-gray-700">
              Betreff
            </label>
            <input
              className="border rounded-lg py-2 px-3 w-full"
              type="text"
              name="subject"
              value={formData.subject}
              onChange={changeFunction}
              placeholder="Betreff"
            />
          </div>
          <div className="mb-4 space-y-2">
            <label className="block font-semibold text-gray-700">
              Nachricht
            </label>
            <textarea
              className="border rounded-lg py-2 px-3 w-full"
              name="message"
              value={formData.message}
              onChange={changeFunction}
              rows="4"
              placeholder="Nachricht"
            ></textarea>
          </div>
          <CardButton label="Nachricht senden" type="submit" fullWidth={true}/>
        </form>
      </div>
    </div>
  )
}

export default ContactSection
