function Imagecards({ images }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-0 mb-6">
      {images.map((imageSrc, index) => (
        <img 
          key={index} 
          src={imageSrc} 
          alt={`Description ${index + 1}`} 
          className="w-full h-auto shadow-md rounded bg-white"
        />
      ))}
    </div>
  );
}

export default Imagecards;