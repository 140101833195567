import React from 'react'

const HeadingParagraph = ({ text, customClasses = "" }) => {
  return (
    <h2 className={`text-3xl sm:text-5xl font-bold px-2 text-gray-900 text-center ${customClasses}`}>
      {text}
    </h2>
  );
};

export default HeadingParagraph;
