import React, { Component } from "react";
import "./Veranstalter.scss";
import Services from "../../container/Veranstalter/Services";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import FragekatalogInfo from "../../container/Veranstalter/FragekatalogInfo";
import ImageSliderWithText from "../../components/HallSmallSlider/ImageSliderWithTextLeft";
import DownloadSection from "../../components/DownloadSection";
import Buehnenbeleuchtung from "../../assets/PDFFiles/Großer Saal/buhnenbeleuchtung_grosser_saal.pdf";
import SaalUebersicht from "../../assets/PDFFiles/Großer Saal/grosser_saal_uebersicht.pdf";
import FoyerStromAnschluesse from "../../assets/PDFFiles/Großer Saal/grosserfoyer_stromanschluesse.pdf";
import SaalStromAnschluesse from "../../assets/PDFFiles/Großer Saal/grossersaal_stromanschluesse.pdf";
import HaengePunkte from "../../assets/PDFFiles/Großer Saal/grossersaal_haengepunkte.pdf";
import FoyerUebersicht from "../../assets/PDFFiles/Großer Saal/grossesfoyer_uebersicht.pdf";
import Kapazitaeten from "../../assets/PDFFiles/Großer Saal/kapazitaet_cd.pdf";
import VenueInformation from "../../assets/PDFFiles/Großer Saal/venue_sheet_msh_grosser_bereich.pdf";
import PageSlider from '../../components/PageSlider'

const downloadSectionConfig = {
  heading: "Allgemeine Veranstaltungsinformationen und Vertragsbedingungen",
  links: [
    {
      url: Buehnenbeleuchtung,
      text: "Bühnenbeleuchtung",
      fileName: "MSH - Großer Saal - Bühnenbeleuchtung",
    },
    {
      url: SaalUebersicht,
      text: "Saal Übersicht",
      fileName: "MSH - Großer Saal - Saal Übersicht",
    },
    {
      url: SaalStromAnschluesse,
      text: "Saal Stromanschlüsse",
      fileName: "MSH - Großer Saal - Saal Stromanschlüsse",
    },

    {
      url: FoyerUebersicht,
      text: "Foyer Übersicht",
      fileName: "MSH - Großer Saal - Foyer Übersicht",
    },
    {
      url: FoyerStromAnschluesse,
      text: "Foyer Stromanschlüsse",
      fileName: "MSH - Großer Saal - Foyer Stromanschlüsse",
    },
    {
      url: Kapazitaeten,
      text: "Kapazitäten",
      fileName: "MSH - Großer Saal - Kapazitäten",
    },
    {
      url: VenueInformation,
      text: "Venue Information",
      fileName: "MSH - Großer Saal - Venue Information",
    },
    {
      url: HaengePunkte,
      text: "Hängepunkte",
      fileName: "MSH - Großer Saal - Hängepunkte",
    },
  ],
};

class Veranstalter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VeranstalterSliderData: [],
      HallePDFData: [],
      expandedCategory: null,
      SmallSliderData: [],
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.VeranstalterSlider)
      .then((response) => {
        this.setState({ VeranstalterSliderData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(AppURL.HallePDF)
      .then((response) => {
        this.setState({ HallePDFData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(AppURL.SmallSlider("organizer"))
      .then((response) => {
        this.setState({ SmallSliderData: response.data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    link.setAttribute("target", "_blank");
    link.click();
  };

  handleAccordionChange = (category) => {
    this.setState((prevState) => ({
      expandedCategory:
        prevState.expandedCategory === category ? null : category,
    }));
  };

  render() {
    const { HallePDFData, expandedCategory } = this.state;

    // Group the PDF data by category
    const groupedPDFData = HallePDFData.reduce((groups, item) => {
      const category = item.category;
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(item);
      return groups;
    }, {});

    return (
      <div>
        <PageSlider data={this.state.VeranstalterSliderData} />
        <section className="py-8 bg-white">
          <div className="sm:container mx-auto">
            <Services />
            {/* ImageSliderWithText Section */}
            <div className="pt-8">
              {this.state.isLoading ? (
                <p>Lade...</p>
              ) : (
                <ImageSliderWithText
                  slides={(this.state.SmallSliderData ?? []).map((item) => ({
                    image: item.slider_image,
                    text: item.slider_paragraph,
                  }))}
                  className="mt-16"
                />
              )}
            </div>
          </div>
        </section>

        <section className="bg-gray-100 py-8">
          <FragekatalogInfo />
        </section>
        <div className="py-8 w-full bg-white">
          <DownloadSection config={downloadSectionConfig} />
        </div>
      </div>
    );
  }
}

export default Veranstalter;
