import React, { useState, useEffect } from "react";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import { useNavigate } from "react-router-dom";
import CardButton from "../../components/CardButton";
import Card from "../../components/Card";
import HeadingParagraph from "../../components/Texts/HeadingParagraph";
import HeadingSmallParagraph from "../../components/Texts/HeadingSmallParagraph";
const FragekatalogInfo = () => {
  const navigate = useNavigate();

  const [fragenkatalogData, setFragenkatalogData] = useState([]);
  const [personCount, setPersonCount] = useState("");
  const [seatingType, setSeatingType] = useState("");
  const [exhibition, setExhibition] = useState("");
  const [catering, setCatering] = useState("Nein");
  const [separateRoom, setSeparateRoom] = useState("Nein");
  const [nebenraum, setNebenraum] = useState("");
  const formInformation = {
    personCount,
    seatingType,
    exhibition,
    catering,
    separateRoom,
    nebenraum,
  };
  useEffect(() => {
    axios
      .get(AppURL.Fragenkatalog)
      .then((response) => {
        setFragenkatalogData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const cateringOnChange = (event) => {
    const catering = event.target.value;
    setCatering(catering);
  };

  const separateRoomOnChange = (event) => {
    const separateRoom = event.target.value;
    setSeparateRoom(separateRoom);
  };

  const exhibitionOnChange = (event) => {
    const exhibition = event.target.value;
    setExhibition(exhibition);
  };

  const nebenraumOnChange = (event) => {
    const nebenraum = event.target.value;
    setNebenraum(nebenraum);
  };
  // Store the form information in the local storage
  localStorage.setItem("formInformation", JSON.stringify(formInformation));

  const submitForm = (event) => {
    event.preventDefault();

    // Determine the route based on the selected options
    let route = "";
    if (personCount === "0-300 Personen") {
      route = "/Halle/Konferenzraum";
    } else if (personCount === "300-600 Personen") {
      route = "/Halle/KleinerSaal";
    } else if (personCount === "600-2100 Personen") {
      route = "/Halle/GrosserSaal";
    }

    // Redirect the user to the selected route
    navigate(route);
  };

  return (
    <section className="sm:rounded-xl">
      <div className="sm:container mx-auto">
        <div className="flex flex-col space-y-4">
          <HeadingParagraph text="Raumfinder" />
          <HeadingSmallParagraph heading="Finden Sie mit wenigen Klicks den richtigen Raum für Ihr Event." />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8">
            {/* Left side - FragenkatalogData (2/3 of the space) */}
            <div className="lg:col-span-2 px-12 py-8">
              {fragenkatalogData.map((data) => (
                <div
                  className="text-center mx-auto"
                  id="Fragekatalog"
                  key={data.id}
                >
                  <img
                    src={data.image}
                    alt="Bild_Veranstaltungskatalog"
                    className="mx-auto mt-3 rounded-lg shadow-md w-full object-contain"
                  />
                  <div className="text-center mt-24"></div>
                  <div className="flex justify-center my-2 mx-4 md:mx-0"></div>
                </div>
              ))}
            </div>
            <div className="lg:col-span-1">
              <form className="w-full h-full bg-white rounded-lg shadow-md p-6">
                <div className="flex flex-wrap -mx-3 h-full">
                  <div className="w-full md:w-full px-3 mb-6">
                    <label
                      className="text-center block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="persons"
                    >
                      Wie viele Personen nehmen an Ihrem Event teil?
                    </label>
                    <select
                      className="text-center block appearance-none w-full bg-white border border-gray-400 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="persons"
                      onChange={(e) => setPersonCount(e.target.value)}
                    >
                      <option value="">Bitte Personenanzahl wählen</option>
                      <option value="0-300 Personen">0-300 Personen</option>
                      <option value="300-600 Personen">300-600 Personen</option>
                      <option value="600-2100 Personen">
                        600-2100 Personen
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-full px-3 mb-6">
                    <label
                      className="text-center block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="seating"
                    >
                      Bestuhlungsart auswählen
                    </label>
                    <select
                      className="text-center block appearance-none w-full bg-white border border-gray-400 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="seating"
                      onChange={(e) => setSeatingType(e.target.value)}
                    >
                      <option value="">Bitte Bestuhlung wählen</option>
                      <option value="Reihenbestuhlung">Reihenbestuhlung</option>
                      <option value="Parlamentarische Bestuhlung">
                        Parlamentarische Bestuhlung
                      </option>
                      <option value="Bankett-Bestuhlung">
                        Bankett-Bestuhlung
                      </option>
                      <option value="Gala-Bestuhlung">Gala-Bestuhlung</option>
                    </select>
                  </div>
                  <div className="w-full md:w-full px-3 mb-6">
                    <label
                      className="text-center block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="exhibition"
                    >
                      Benötigen Sie eine Ausstellungsfläche?
                    </label>
                    <select
                      className="text-center block appearance-none w-full bg-white border border-gray-400 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="exhibition"
                      onChange={(e) => setExhibition(e.target.value)}
                    >
                      <option value="">Bitte wählen</option>
                      <option value="Nein">Nein</option>
                      <option value="200">
                        Ausstellungsfläche bis zu max. 200 Qm
                      </option>
                      <option value="500">
                        Ausstellungsfläche bis zu max. 500 Qm
                      </option>
                      <option value="More than 500">
                        Ausstellungsfläche mehr als 500 Qm
                      </option>
                    </select>
                  </div>
                  <div className="w-full md:w-full px-3 mb-6">
                    <label
                      className="text-center block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="catering"
                    >
                      Wünschen Sie ein Catering?
                    </label>
                    <select
                      className="text-center block appearance-none w-full bg-white border border-gray-400 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="catering"
                      onChange={cateringOnChange}
                    >
                      <option value="">Bitte wählen</option>
                      <option value="Nein">Nein</option>
                      <option value="Ja">Ja</option>
                    </select>
                  </div>
                  {catering === "Ja" ? (
                    <div className="w-full md:w-full px-3 mb-6">
                      <label
                        className="text-center block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="separateRoom"
                      >
                        Wünschen Sie das Catering in einen separaten Raum?
                      </label>
                      <select
                        className="text-center block appearance-none w-full bg-white border border-gray-400 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="separateRoom"
                        onChange={separateRoomOnChange}
                      >
                        <option value="">Bitte wählen</option>
                        <option value="Nein">Nein</option>
                        <option value="Ja">Ja</option>
                      </select>
                    </div>
                  ) : null}
                  <div className="w-full md:w-full px-3 mb-6">
                    <label
                      className="text-center block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="additionalRooms"
                    >
                      Benötigen Sie Nebenräume?
                    </label>
                    <select
                      className="text-center block appearance-none w-full bg-white border border-gray-400 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="additionalRooms"
                      onChange={nebenraumOnChange}
                    >
                      <option value="">Bitte wählen</option>
                      <option value="Nein">Nein</option>
                      <option value="Ja">Ja</option>
                    </select>
                  </div>
                  {nebenraum === "Ja" ? (
                    <div className="w-full md:w-full px-3 mb-6">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="numberOfRooms"
                      >
                        Wie viele Nebenräume werden Sie benötigen?
                      </label>
                      <select
                        className="block appearance-none w-full bg-white border border-gray-400 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="numberOfRooms"
                      >
                        <option value="">Bitte wählen</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="More">Mehr als 3</option>
                      </select>
                    </div>
                  ) : null}
                  <div className="flex-grow" />
                  <div className="w-full px-3 mt-auto">
                    <CardButton label="Fragenkatalog abschicken" type="button" fullWidth={true} clickHandler={submitForm} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FragekatalogInfo;
