import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';

const SearchBox = ({ onSearch }) => {
    const [input, setInput] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(input); 
  };

  return (
    <form onSubmit={handleSearch} className="flex items-center bg-primary-2 rounded overflow-hidden">
      <input
        type="text"
        placeholder="Veranstaltungen durchsuchen..(Mehr Informationen von VK notwendig)"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className="flex-grow p-2 text-white bg-primary-2 placeholder-white"
      />
      <button type="submit" className="p-2">
        <FiSearch className="text-white" />
      </button>
    </form>
  );
};

export default SearchBox;