import React from 'react'
import { Link } from 'react-router-dom'
import CardButton from '../../components/CardButton'

const HalleCard = ({ imageSrc, heading, paragraph, linkTo, buttonLabel}) => {
  return (
    <div className="">
      <div className="flex flex-col bg-white shadow-md h-full overflow-hidden sm:rounded-xl">
        <img
          src={imageSrc}
          alt="Meistersingerhalle Nürnberg Leistungen"
          className="w-full h-2/3 object-cover"
        />
        <h4 className="text-center font-semibold text-2xl text-dark mt-4">
          {heading}
        </h4>
        <p className="text-center text-body-color text-md font-bold mt-2 px-2">
          {paragraph}
        </p>
        <div className="flex-grow mb-4" />
        <Link to={linkTo} className="flex justify-center mb-4">
          <CardButton label={buttonLabel} />
        </Link>
      </div>
    </div>
  )
}

export default HalleCard
