import React, { Component } from "react";
import NewsPost from "../../container/Besuch/News.jsx";
import Informationen from "../../container/Besuch/Informationen";
import axios from "axios";
import AppURL from "../../api/AppUrl"
import PageSlider from '../../components/PageSlider'

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BesucherSliderData: [],
    }
  }

  componentDidMount() {
    axios.get(AppURL.BesucherSlider)
    .then(response => {
      this.setState({ BesucherSliderData: response.data });
    }) 
    .catch(error => {
      console.log(error);
    });
  }

  render() {
    return (
      <div>
        <PageSlider data={this.state.BesucherSliderData} />
        <Informationen />
        <NewsPost />
      </div>
    );
  }
}

export default News;
