import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import DropDown from './DropDown'

const DropDownItem = ({ item }) => {
  const [isSubDropDownItemVisible, setSubDropDownItemVisible ] = useState(false)

  return (
    <div
      onMouseEnter={() => setSubDropDownItemVisible(true)}
      onMouseLeave={() => setSubDropDownItemVisible(false)}
    >
      <NavLink className="px-4 w-full" to={`${item.link}`}>
        {item.title}
      </NavLink>

      { isSubDropDownItemVisible && item.subLink && (
        <div className="absolute ml-[179px] -mt-8">
          <DropDown array={item.subLink} isSubDropdown={true} />
        </div>
      )}
    </div>
  )
}

export default DropDownItem
