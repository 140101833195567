import React from "react";
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import AboutPage from "./pages/About/About";
import EventsPage from "./pages/Events/Events";
import NewsPage from "./pages/News/News";
import VeranstalterPage from "./pages/Veranstalter/Veranstalter";
import HallePage from "./pages/Halle/Halle";
import GrosserSaal from "./pages/Halle/GroßerSaal/GroßerSaal";
import Konferenzraum from "./pages/Halle/Konferenzraeume/Conference";
import KleinerSaal from "./pages/Halle/KleinerSaal/KleinerSaal";
import Orgel from "./pages/Halle/Orgel/Orgel";
import KontaktPage from "./pages/Kontakt/Kontakt";
import "./index.css";
import Footer from "./container/Footer/Footer";
import NavBar from './components/Navbar/NavBar'
import HomePage from "./pages/Home/Home";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import CookieConsent from "react-cookie-consent";
import SingleEvent from "./pages/Events/SingleEvent";
import Anreise from "./pages/About/Anreise";
import Barrierefreiheit from "./pages/About/Barrierefreiheit";
import Besuch from "./pages/About/Besuch";
import Gastronomie from "./pages/About/Gastronomie";
import Ticketinformationen from "./pages/About/Ticketinformationen";
import Impressum from "./pages/Impressum/Impressum";
import "tw-elements";
import SingleNews from "./pages/News/SingleNews";
import { default as ScrollToTopHook } from "./hooks/scroll-to-top.js";
import Garderobe from "./pages/About/Garderobe.js";
import Hausordnung from "./pages/About/Hausordnung.js";
import Legal from "./pages/legal/legal.jsx";
import { TextToSpeechProvider } from "./components/speakToText/TextSpeechProvider.jsx";
import SpeakButton from "./components/speakToText/SpeakToText.jsx";
import ThreeSixty from "./pages/Halle/360Grad/360Grad.js"
const domNode = document.getElementById('root')
const root = createRoot(domNode)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTopHook />
      <TextToSpeechProvider>
      <SpeakButton />
      <CookieConsent
        cookieName="CookieConsent Temporär Meistersingerhalle"
        enableDeclineButton
        declineButtonText="Ich lehne ab"
        location="bottom"
        style={{ background: "#000", textAlign: "left" }}
        buttonStyle={{
          fontSize: "16px",
          color: "#fff",
          backgroundColor: "green",
        }}
        buttonText="Ich stimme zu"
        expires={365}
      >
        Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren,
        Funktionen für soziale Medien anbieten zu können und die Zugriffe auf
        unsere Webseite anonym und datenschutzkonform zu analysieren. Dazu geben
        wir mit Ihrer – jederzeit widerruflichen – Einwilligung Informationen zu
        Ihrer Verwendung unserer Webseite an unsere Partner für soziale Medien
        (Cookies im Bereich Marketing) weiter. Unsere Partner führen diese
        Informationen aus den Marketing-Cookies möglicherweise mit weiteren
        Daten zusammen, die Sie ihnen auf anderen Webseiten oder Social
        Media-Kanälen bereitgestellt haben. Sofern Sie dem nicht widersprechen,
        werden wir die Nutzung unserer Webseite durch Sie in anonymisierter Form
        analysieren (Cookies im Bereich Statistik). Eine Weitergabe dieser Daten
        an Dritte erfolgt hierbei nicht. Details zur Verarbeitung Ihrer Daten
        finden Sie in unserer{" "}
        <Link
          to="/Datenschutz"
          style={{
            textDecoration: "underline",
            textDecorationColor: "#e30423",
          }}
        >
          Datenschutzerklärung{" "}
        </Link>
        , Informationen zum Website-Herausgeber in unserem{" "}
        <Link
          to="/Impressum"
          style={{
            textDecoration: "underline",
            textDecorationColor: "#e30423",
          }}
        >
          Impressum
        </Link>
        .
      </CookieConsent>
      <NavBar />
      <Routes>
        <Route index element={<HomePage />} />
        <Route exact path="/Home" element={<HomePage />} />
        <Route exact path="/Historie" element={<AboutPage />} />
        <Route exact path="/Veranstaltungen" element={<EventsPage />} />
        <Route
          exact
          path="/Veranstaltungen/:ID/:Date"
          element={<SingleEvent />}
        />
        <Route exact path="/Besuch" element={<NewsPage />} />
        <Route exact path="/news/:news_code" element={<SingleNews />} />
        <Route exact path="/Veranstalter" element={<VeranstalterPage />} />
        <Route exact path="/Halle" element={<HallePage />} />
        <Route exact path="/Halle/GrosserSaal" element={<GrosserSaal />} />
        <Route
          exact
          path="/Halle/Konferenzraum"
          element={<Konferenzraum hallId={0} key={0} />}
        />
        <Route
          exact
          path="/Halle/Konferenzsaal1"
          element={<Konferenzraum hallId={1} key={1} />}
        />
        <Route
          exact
          path="/Halle/Konferenzsaal2"
          element={<Konferenzraum hallId={2} key={2} />}
        />
        <Route
          exact
          path="/Halle/Konferenzsaal3"
          element={<Konferenzraum hallId={3} key={3} />}
        />
        <Route
          exact
          path="/Halle/Konferenzsaal4"
          element={<Konferenzraum hallId={4} key={4} />}
        />
        <Route
          exact
          path="/Halle/Konferenzsaal5"
          element={<Konferenzraum hallId={5} key={5} />}
        />
        <Route
          exact
          path="/Halle/Konferenzsaal6"
          element={<Konferenzraum hallId={6} key={6} />}
        />
        <Route
          exact
          path="/Halle/Konferenzsaal7"
          element={<Konferenzraum hallId={7} key={7} />}
        />
        <Route
          exact
          path="/Halle/Konferenzsaal8"
          element={<Konferenzraum hallId={8} key={8} />}
        />
        <Route
          exact
          path="/Halle/Konferenzsaal9"
          element={<Konferenzraum hallId={9} key={9} />}
        />
        <Route exact path="/Halle/KleinerSaal" element={<KleinerSaal />} />
        <Route exact path="/Halle/Orgel" element={<Orgel />} />
        <Route exact path="/Anreise" element={<Anreise />} />
        <Route exact path="/Barrierefreiheit" element={<Barrierefreiheit />} />
        <Route exact path="/Besuch" element={<Besuch />} />
        <Route exact path="/Gastronomie" element={<Gastronomie />} />
        <Route exact path="/Garderobe" element={<Garderobe />} />
        <Route exact path="/Hausordnung" element={<Hausordnung />} />
        <Route
          exact
          path="/Ticketinformationen"
          element={<Ticketinformationen />}
        />
        <Route exact path="/Contact" element={<KontaktPage />} />
        <Route exact path="/Impressum" element={<Impressum />} />
        <Route exact path="/Urheberrecht" element={<Legal />} />
        <Route exact path="/Halle/360Grad" element={<ThreeSixty />} />
      </Routes>
      <Footer />
      </TextToSpeechProvider>
      <ScrollToTop />
    </BrowserRouter>
  </React.StrictMode>
);
