import React, { Component } from "react";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import Card from '../../components/Card'

export class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardsData: [],
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.VeranstalterCards)
      .then((response) => {
        this.setState({ cardsData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { cardsData } = this.state;

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {cardsData.map((card) => (
          <Card card={card} key={card.id}/>
        ))}
      </div>
    );
  }
}

export default Cards;
