import React from "react";
import backgroundImage from "../../assets/MSH_Außenansicht_4.jpg";
import FerdinLogo from "../../assets/ferdin-logo.png";
import CardButton from "../../components/CardButton";
function Gastronomie() {
  return (
    <div>
      <section
        className="text-white py-40 lg:h-1/2 lg:items-center bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.1), rgba(128, 128, 128, 0.3)), url(${backgroundImage})`,
        }}
      >
        {" "}
        <div className="mx-auto max-w-screen-xl px-4">
          {/*           <div className="mx-auto max-w-3xl text-center">
            <h1 className="bg-gradient-to-r from-red-300 via-red-500 to-gray-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
              Pausenbewirtung
            </h1>

            <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
              Hier finden Sie alle Informationen zur Pausenbewirtung
            </p>
          </div> */}
        </div>
      </section>
      <section className="bg-gray-100 py-8">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-4">
            {/* Text Content */}
            <div>
              <h2 className="text-2xl font-bold mb-4 text-center md:text-center">
                Pausenbewirtung
              </h2>

              <p className="mb-4">
                Ob Kaffee, Wein oder Saft, die Ferdin Catering GmbH bietet Ihnen
                eine große Auswahl an Getränken und Speisen, die bereits vor
                Beginn der Veranstaltung beim ORDER POINT bestellt werden
                können. In der Pause steht Ihre Bestellung dann schon für Sie
                bereit. Genießen Sie die Veranstaltung und die Pause!
              </p>
              <ul>
                <li>
                  <a
                    href="/path-to-pdf/Pausenbewirtung.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="text-red-500 hover:underline"
                  >
                    Pausenbewirtung (PDF, 36 KB) Muss aktualisiert werden –
                    schöner Flyer, (Werbung)
                  </a>
                </li>
              </ul>
              <div className="flex justify-center items-center h-[300px]">
                <a
                  href="https://ferdin-catering.de/meistersingerhalle"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={FerdinLogo}
                    className="mb-4"
                    alt="Ferdin Catering GmbH Logo"
                  />
                  <CardButton label="Zu Ferdin Catering"></CardButton>
                </a>
              </div>
            </div>

            {/* Image Content */}
            <div className="order-first md:order-none">
              {/* Replace 'path-to-your-image.jpg' with the actual path to your image */}
              <img
                src={backgroundImage}
                alt="Meistersingerhalle Hausordnung"
                className="w-full h-auto object-cover rounded-xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Gastronomie;

<section className="bg-gray-100 py-8">
  <div className="sm:container mx-auto flex justify-center">
    <div className="max-w-3xl">
      <h3 className="text-xl font-semibold mt-4">Pausenbewirtung</h3>
      <p>
        Ob Kaffee, Wein oder Saft, die Ferdin Catering GmbH bietet Ihnen eine
        große Auswahl an Getränken und Speisen, die bereits vor Beginn der
        Veranstaltung beim ORDER POINT bestellt werden können. In der Pause
        steht Ihre Bestellung dann schon für Sie bereit. Genießen Sie die
        Veranstaltung und die Pause!
      </p>
      <ul>
        <li>
          <a
            href="/path-to-pdf/Pausenbewirtung.pdf"
            target="_blank"
            rel="noreferrer"
            className="text-red-500 hover:underline"
          >
            Pausenbewirtung (PDF, 36 KB) Muss aktualisiert werden – schöner
            Flyer, (Werbung)
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>;
