import React from 'react'

const SliderHeading = ({ text }) => {
  return (
    <span className="text-center absolute bold text-[40px] sm:text-[70px] md:text-[75px] lg:text-[75px] self-center text-white">
      { text }
    </span>
  )
}

export default SliderHeading
