import React from 'react'

const Paragraph = ({ text }) => {
  return (
    <p className="text-center text-base text-black">
      {text}
    </p>
  )
}

export default Paragraph
