import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import DropDown from "./DropDown";
import { useLocation } from "react-router-dom";

const Item = ({ item, active }) => {
  const [isDropDownVisible, setDropDownVisible] = useState(false);
  const location = useLocation();
  const isLocationMatch = () => {
    if (item.link === "/") {
      return location.pathname === "/";
    }
    return location.pathname.startsWith(item.link);
  };
  return (
    <div
      onMouseEnter={() => setDropDownVisible(true)}
      onMouseLeave={() => setDropDownVisible(false)}
    >
      <NavLink
        className={`font-bold p-[15px] md:p-[15px] lg:p-[10px] xl:p-[20px] text-black rounded-lg hover:bg-primary-2 hover:text-white transition duration-150 ease-in-out uppercase ${
          isLocationMatch() ? "bg-primary-2 text-white" : ""
        }`}
        to={item.link}
        onClick={() => setDropDownVisible(false)}
      >
        {item.title}

        {isDropDownVisible && item.subLink && (
          <div
            className="hidden lg:block lg:absolute mt-1.5 xl:mt-3 font-normal"
            key={item.sublink}
          >
            <DropDown array={item.subLink} />
          </div>
        )}
      </NavLink>
    </div>
  );
};

export default Item;
