import React from "react";
import { FaBus, FaCar, FaTrain, FaPlane, FaMapMarker } from "react-icons/fa";
import AutoAnreise from "../../assets/PDFFiles/Anfahrt/anfahrt_corpdes.pdf";
import backgroundImage from "../../assets/MSH_Außenansicht_4.jpg";

function TicketInformationen() {
  return (
    <div>
      <section
        className="text-white py-40 lg:h-1/2 lg:items-center bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.1), rgba(128, 128, 128, 0.3)), url(${backgroundImage})`,
        }}
      >
        {" "}
        <div className="mx-auto max-w-screen-xl px-4">
          {/*           <div className="mx-auto max-w-3xl text-center">
            <h1 className="bg-gradient-to-r from-red-300 via-red-500 to-gray-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
              Ticketinformationen
            </h1>

            <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
              Hier finden Sie alle Informationen zu Ihren Tickets
            </p>
          </div> */}
        </div>
      </section>
      <section className="bg-gray-100 py-8">
        <div className="sm:container mx-auto flex justify-center">
          {/* Main Content */}
          <div className="max-w-3xl">
            <h2 className="text-2xl font-bold mb-4">So finden Sie uns</h2>

            <p className="mb-4">
              Die Meistersingerhalle liegt mitten im Herzen Nürnbergs und ist
              trotzdem mit jedem Verkehrsmittel einfach und schnell zu
              erreichen.
            </p>

            <p className="mb-4">
              Damit Sie sich leichter orientieren können und ohne Probleme den
              Weg zu uns finden, haben wir Kartenmaterial, Wegbeschreibungen und
              Linienpläne zusammengestellt.
            </p>

            {/* Anreise im Nahverkehr */}
            <h3 className="text-xl font-semibold mt-4">
              Anreise im Nahverkehr
            </h3>

            <div className="mb-4">
              <p className="inline-block mb-2">
                Wenn Sie mit den öffentlichen Verkehrsmitteln zu uns kommen
                möchten, klicken Sie bitte einfach auf "öffentliche
                Verkehrsmittel". Sie werden weitergeleitet auf den Fahrplan der
                VGN. Hier müssen Sie lediglich Ihre Abfahrt-Haltestelle eingeben
                und erhalten sofort Ihren personalisierten Fahrplan.
              </p>
            </div>

            {/* Meistersingerhalle Nürnberg - Großer Saal */}
            <h3 className="text-lg font-semibold">
              Meistersingerhalle Nürnberg - Großer Saal
            </h3>
            <p className="mb-2">Schultheißallee 2-4</p>
            <p className="mb-2">90478 Nürnberg</p>
            <div className="mb-4">
              <a
                href="https://www.vgn.de/verbindungen/?place_destination=Nürnberg&name_destination=internet%20&type_destination=any&anyObjFilter_destination=12&execInst=verifyOnly&sessionID=0"
                target="_blank"
                rel="noreferrer"
                className="text-red-500 hover:underline"
              >
                Öffentliche Verkehrsmittel
              </a>
              <br></br>
              <a
                href="https://geoportal.nuernberg.de/masterportal/stadtplan/?featureviaurl=%5B%7B%22layerId%22%3A%22urlPointFeatures%22%2C%22features%22%3A%5B%7B%22coordinates%22%3A%5B652624.9649999999%2C5478254.218699999%5D%2C%22label%22%3A%22Schulthei%C3%9Fallee%202-4%22%7D%5D%7D%5D&center=652624.9649999999,5478254.218699999&zoomlevel=5"
                target="_blank"
                rel="noreferrer"
                className="text-red-500 hover:underline mt-2"
              >
                Stadtplan
              </a>
            </div>

            {/* Meistersingerhalle Nürnberg - Kleiner Saal */}
            <h3 className="text-lg font-semibold">
              Meistersingerhalle Nürnberg - Kleiner Saal
            </h3>
            <p className="mb-2">Münchener Straße 19</p>
            <p className="mb-2">90478 Nürnberg</p>

            <div className="mb-4">
              <a
                href="https://www.vgn.de/verbindungen/?place_destination=N%C3%BCrnberg&name_destination=M%fcnchener%20Stra%dfe%2019&type_destination=any&anyObjFilter_destination=12&execInst=verifyOnly&sessionID=0"
                className="text-red-500 hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                Öffentliche Verkehrsmittel
              </a>
              <br></br>
              <a
                href="https://geoportal.nuernberg.de/masterportal/stadtplan/?featureviaurl=%5B%7B%22layerId%22%3A%22urlPointFeatures%22%2C%22features%22%3A%5B%7B%22coordinates%22%3A%5B652536.0433%2C5478200.6504%5D%2C%22label%22%3A%22M%C3%BCnchener%20Stra%C3%9Fe%2019%22%7D%5D%7D%5D&center=652536.0433,5478200.6504&zoomlevel=5"
                target="_blank"
                rel="noreferrer"
                className="text-red-500 hover:underline mt-2"
              >
                Stadtplan
              </a>
            </div>

            {/* Meistersingerhalle Nürnberg - Konferenzräume und Verwaltung */}
            <h3 className="text-lg font-semibold">
              Meistersingerhalle Nürnberg - Konferenzräume und Verwaltung
            </h3>
            <p className="mb-2">Münchener Straße 21</p>
            <p className="mb-2">90478 Nürnberg</p>

            <div className="mb-4">
              <a
                href="https://www.vgn.de/verbindungen/?place_destination=N%C3%BCrnberg&name_destination=M%fcnchener%20Stra%dfe%2021&type_destination=any&anyObjFilter_destination=12&execInst=verifyOnly&sessionID=0"
                className="text-red-500 hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                Öffentliche Verkehrsmittel
              </a>
              <br></br>
              <a
                href="https://geoportal.nuernberg.de/masterportal/stadtplan/?featureviaurl=%5B%7B%22layerId%22%3A%22urlPointFeatures%22%2C%22features%22%3A%5B%7B%22coordinates%22%3A%5B652528.7669000002%2C5478182.3487%5D%2C%22label%22%3A%22M%C3%BCnchener%20Stra%C3%9Fe%2021%22%7D%5D%7D%5D&center=652528.7669000002,5478182.3487&zoomlevel=5"
                target="_blank"
                rel="noreferrer"
                className="text-red-500 hover:underline mt-2"
              >
                Stadtplan
              </a>
            </div>

            {/* Anreise von außerhalb */}
            <h3 className="text-xl font-semibold mt-4">
              Anreise von außerhalb
            </h3>

            <div className="mb-4">
              <FaCar className="inline-block mr-2 text-red-500" />
              <a
                href={AutoAnreise}
                target="_blank"
                rel="noreferrer"
                className="text-red-500 hover:underline inline-block"
              >
                Mit dem Auto
              </a>
            </div>

            <div className="mb-4">
              <FaTrain className="inline-block mr-2 text-red-500" />
              <a
                href="https://www.bahn.de"
                target="_blank"
                rel="noreferrer"
                className="text-red-500 hover:underline inline-block"
              >
                Mit der Bahn
              </a>
            </div>

            <div className="mb-4">
              <FaPlane className="inline-block mr-2 text-red-500" />
              <a
                href="https://www.airport-nuernberg.de"
                target="_blank"
                rel="noreferrer"
                className="text-red-500 hover:underline inline-block"
              >
                Mit dem Flugzeug
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TicketInformationen;
