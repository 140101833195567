import React, { createContext, useContext, useState } from 'react';

// Create a Context for the text-to-speech feature
const TextToSpeechContext = createContext({
  speakText: () => console.warn("speakText called outside of TextToSpeechProvider"),
});

// Hook for easy consumption of context
export const useTextToSpeech = () => useContext(TextToSpeechContext);

// Provider component to wrap your app/components
export const TextToSpeechProvider = ({ children }) => {
  const [text, setText] = useState('');

  const speakText = (textToSpeak) => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel(); // Cancel any ongoing speech
      const utterance = new SpeechSynthesisUtterance(textToSpeak);
      window.speechSynthesis.speak(utterance);
    } else {
      console.error("Dieser Browser unterstüzt keine Sprachen Synthesis.");
    }
  };

  // Value provided to the consuming components
  const value = { text, setText, speakText };

  return <TextToSpeechContext.Provider value={value}>{children}</TextToSpeechContext.Provider>;
};
