import React from 'react'

const FooterLink = ({ text, link }) => {
  return (
    <li className="mb-4 text-left">
      <a href={ link } target="_blank" rel="noreferrer" className="hover:underline text-white-500">
        { text }
      </a>
    </li>
  )
}

export default FooterLink