import React, { Component } from "react";
import PanoImage from "../../assets/360Degree/EingangAussenbereich.jpg";
import PanoImage2 from "../../assets/360Degree/EingangEingang.jpg";
import PanoImage3 from "../../assets/360Degree/EingangEnde.jpg";
import PanoImage4 from "../../assets/360Degree/EingangKonferenzraum6.jpg";
import PanoImage5 from "../../assets/360Degree/konferenzraum6.jpg";
import PanoImage6 from "../../assets/360Degree/Konferenzraum7Eingang.jpg";
import PanoImage7 from "../../assets/360Degree/Konferenzraum7Mitte.jpg";
import PanoImage8 from "../../assets/360Degree/foyerGrossEingang.jpg";
import PanoImage9 from "../../assets/360Degree/kleinerSaalEingang.jpg";
import PanoImage10 from "../../assets/360Degree/kleinerSaalMitte.jpg";
import PanoImage11 from "../../assets/360Degree/kleinerSaalBuehne.jpg";
import PanoImage12 from "../../assets/360Degree/kleinesFoyerSaaleingang.jpg";
import PanoImage13 from "../../assets/360Degree/foyerGrossEcke.jpg";
import PanoImage14 from "../../assets/360Degree/foyerGrossAussenbereichEingang.jpg";
import PanoImage15 from "../../assets/360Degree/foyerGrossAussenbereich.jpg";
import PanoImage16 from "../../assets/360Degree/foyerGrossMitte.jpg";
import PanoImage17 from "../../assets/360Degree/foyerGrossZwischengang.jpg";
import PanoImage18 from "../../assets/360Degree/zwischengangFoyerGross.jpg";
import PanoImage19 from "../../assets/360Degree/zwischengangMitte.jpg";
import PanoImage20 from "../../assets/360Degree/zwischengangFoyerKlein.jpg";
import PanoImage21 from "../../assets/360Degree/kleinesFoyerZwischengang.jpg";
import PanoImage22 from "../../assets/360Degree/kleinesFoyerMitteRechts.jpg";
import PanoImage23 from "../../assets/360Degree/kleinesFoyerSaaleingang.jpg";
import PanoImage24 from "../../assets/360Degree/kleinesFoyerAusgang.jpg";
import PanoImage25 from "../../assets/360Degree/GarderobeAusgang.jpg";
import PanoImage26 from "../../assets/360Degree/GarderobeAussenbereich.jpg";
import PanoImage27 from "../../assets/360Degree/GarderobeMitte.jpg";
import PanoImage28 from "../../assets/360Degree/foyerGrossEingangHalleMitte.jpg";
import PanoImage29 from "../../assets/360Degree/foyerGrossEnde.jpg";
import PanoImage30 from "../../assets/360Degree/grosserSaalEingangMitte.jpg";
import PanoImage31 from "../../assets/360Degree/foyerGrossEnde.jpg";
import PanoImage32 from "../../assets/360Degree/grosserSaalMitteMittig.jpg";
import PanoImage35 from "../../assets/360Degree/grosserSaalVorneMittig.jpg";
import PanoImage36 from "../../assets/360Degree/grosserSaalBuehne.jpg";
import PanoImage37 from "../../assets/360Degree/grosserSaalOrgel.jpg";
import PanoImage38 from "../../assets/360Degree/grosserSaalBalkon.jpg";
import PanoImage39 from "../../assets/360Degree/foyerGrossEndeGang.jpg";
import { Pannellum } from "pannellum-react";
import "./360Degree.scss";
import HighlightParagraph from '../../components/Texts/HighlightParagraph'
import HeadingParagraph from '../../components/Texts/HeadingParagraph'
import CardButton from '../CardButton'

export class Degree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: PanoImage,
      hotspots: [
        {
          name: PanoImage,
          spots: [
            {
              text: "Vorhalle",
              pitch: 2,
              yaw: 55,
              imageToLoad: PanoImage2,
              newHotspots: [],
            },
            //... More hotspots for PanoImage1 if needed
          ],
        },
        {
          name: PanoImage2,
          spots: [
            {
              text: "Garderobe",
              pitch: 2,
              yaw: -100,
              imageToLoad: PanoImage27,
              newHotspots: [],
            },
            {
              text: "Ausgang",
              pitch: -5,
              yaw: 220,
              imageToLoad: PanoImage,
              newHotspots: [],
            },
            {
              text: "Ende Vorhalle",
              pitch: -3,
              yaw: -5,
              imageToLoad: PanoImage3,
              newHotspots: [],
            },
            {
              text: "Konferenzraum 6",
              pitch: -3,
              yaw: 20,
              imageToLoad: PanoImage4,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage3,
          spots: [
            {
              text: "Eingang Vorhalle",
              pitch: -0.5,
              yaw: 47,
              imageToLoad: PanoImage2,
              newHotspots: [],
            },
            {
              text: "Konferenzraum 7",
              pitch: -0.5,
              yaw: -50,
              imageToLoad: PanoImage6,
              newHotspots: [],
            },
            {
              text: "Eingang großes Foyer",
              pitch: -0.5,
              yaw: -130,
              imageToLoad: PanoImage8,
              newHotspots: [],
            },
            {
              text: "Eingang kleiner Saal",
              pitch: -0.5,
              yaw: -220,
              imageToLoad: PanoImage9,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage4,
          spots: [
            {
              text: "Vorhalle",
              pitch: 5,
              yaw: 85,
              imageToLoad: PanoImage2,
              newHotspots: [],
            },
            {
              text: "Konferenzraum 6",
              pitch: 1,
              yaw: 255,
              imageToLoad: PanoImage5,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage5,
          spots: [
            {
              text: "Konferenzraum Eingang",
              pitch: -3,
              yaw: 120,
              imageToLoad: PanoImage4,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage6,
          spots: [
            {
              text: "Konferenzraum Mitte",
              pitch: -3,
              yaw: -10,
              imageToLoad: PanoImage7,
              newHotspots: [],
            },
            {
              text: "Vorhalle",
              pitch: -3,
              yaw: 195,
              imageToLoad: PanoImage3,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage7,
          spots: [
            {
              text: "Konferenzraum Eingang",
              pitch: -3,
              yaw: 155,
              imageToLoad: PanoImage6,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage8,
          spots: [
            {
              text: "Großes Foyer Ecke",
              pitch: -2,
              yaw: -30,
              imageToLoad: PanoImage13,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage9,
          spots: [
            {
              text: "Kleiner Saal Mitte",
              pitch: -3,
              yaw: 155,
              imageToLoad: PanoImage10,
              newHotspots: [],
            },
            {
              text: "Kleines Foyer",
              pitch: -3,
              yaw: -90,
              imageToLoad: PanoImage12,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage10,
          spots: [
            {
              text: "Kleiner Saal Hinten",
              pitch: -3,
              yaw: 280,
              imageToLoad: PanoImage9,
              newHotspots: [],
            },
            {
              text: "Bühne kleiner Saal",
              pitch: 1,
              yaw: 120,
              imageToLoad: PanoImage11,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage11,
          spots: [
            {
              text: "Kleiner Saal Hinten",
              pitch: -3,
              yaw: -13,
              imageToLoad: PanoImage9,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage13,
          spots: [
            {
              text: "Eingang Außenbereich",
              pitch: -3,
              yaw: -5,
              imageToLoad: PanoImage14,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage14,
          spots: [
            {
              text: "Außenbereich",
              pitch: -3,
              yaw: -9,
              imageToLoad: PanoImage15,
              newHotspots: [],
            },
            {
              text: "Großes Foyer",
              pitch: -2,
              yaw: 80,
              imageToLoad: PanoImage16,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage15,
          spots: [
            {
              text: "Zum Innenbereich",
              pitch: -3,
              yaw: -175,
              imageToLoad: PanoImage14,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage16,
          spots: [
            {
              text: "Zum Foyergang",
              pitch: -2,
              yaw: 70,
              imageToLoad: PanoImage14,
              newHotspots: [],
            },
            {
              text: "Zwischengang kleines Foyer",
              pitch: -2,
              yaw: -195,
              imageToLoad: PanoImage17,
              newHotspots: [],
            },
            {
              text: "Großes Foyer Mitte",
              pitch: -2,
              yaw: -10,
              imageToLoad: PanoImage28,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage17,
          spots: [
            {
              text: "Foyer Mitte",
              pitch: -2,
              yaw: -165,
              imageToLoad: PanoImage16,
              newHotspots: [],
            },
            {
              text: "Foyer kleiner Saal",
              pitch: -2,
              yaw: 40,
              imageToLoad: PanoImage18,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage18,
          spots: [
            {
              text: "Großes Foyer",
              pitch: -2,
              yaw: 70,
              imageToLoad: PanoImage17,
              newHotspots: [],
            },
            {
              text: "Foyer kleiner Saal Mitte",
              pitch: -2,
              yaw: -185,
              imageToLoad: PanoImage19,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage19,
          spots: [
            {
              text: "Raummitte",
              pitch: -1,
              yaw: 20,
              imageToLoad: PanoImage18,
              newHotspots: [],
            },
            {
              text: "Raumende",
              pitch: -2,
              yaw: -165,
              imageToLoad: PanoImage20,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage20,
          spots: [
            {
              text: "Raummitte",
              pitch: -1,
              yaw: 180,
              imageToLoad: PanoImage19,
              newHotspots: [],
            },
            {
              text: "kleines Foyer",
              pitch: -2,
              yaw: 80,
              imageToLoad: PanoImage21,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage21,
          spots: [
            {
              text: "Bar",
              pitch: -1,
              yaw: 1,
              imageToLoad: PanoImage19,
              newHotspots: [],
            },
            {
              text: "Raummitte",
              pitch: -10,
              yaw: 220,
              imageToLoad: PanoImage22,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage22,
          spots: [
            {
              text: "Raumende",
              pitch: -3,
              yaw: 80,
              imageToLoad: PanoImage21,
              newHotspots: [],
            },
            {
              text: "Ausgang & Kleiner Saal",
              pitch: -5,
              yaw: 140,
              imageToLoad: PanoImage23,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage23,
          spots: [
            {
              text: "Zwischengang",
              pitch: -3,
              yaw: -20,
              imageToLoad: PanoImage21,
              newHotspots: [],
            },
            {
              text: "Ausgang",
              pitch: -5,
              yaw: 80,
              imageToLoad: PanoImage24,
              newHotspots: [],
            },
            {
              text: "Kleiner Saal",
              pitch: -5,
              yaw: 40,
              imageToLoad: PanoImage9,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage24,
          spots: [
            {
              text: "Ausgang",
              pitch: -5,
              yaw: 5,
              imageToLoad: PanoImage25,
              newHotspots: [],
            },
            {
              text: "Kleines Foyer",
              pitch: -1,
              yaw: 240,
              imageToLoad: PanoImage23,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage25,
          spots: [
            {
              text: "Außenbereich",
              pitch: -15,
              yaw: 190,
              imageToLoad: PanoImage26,
              newHotspots: [],
            },
            {
              text: "Garderobe Mitte",
              pitch: -1,
              yaw: -270,
              imageToLoad: PanoImage27,
              newHotspots: [],
            },
            {
              text: "Kleines Foyer",
              pitch: -10,
              yaw: 270,
              imageToLoad: PanoImage24,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage26,
          spots: [
            {
              text: "Eingang",
              pitch: -10,
              yaw: 190,
              imageToLoad: PanoImage25,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage27,
          spots: [
            {
              text: "Eingang kleiner Saal",
              pitch: -5,
              yaw: 230,
              imageToLoad: PanoImage25,
              newHotspots: [],
            },
            {
              text: "Eingang Konferenzräume",
              pitch: -5,
              yaw: 53,
              imageToLoad: PanoImage2,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage28,
          spots: [
            {
              text: "Foyer Ende",
              pitch: -5,
              yaw: 240,
              imageToLoad: PanoImage29,
              newHotspots: [],
            },
            {
              text: "Großer Saal",
              pitch: -5,
              yaw: -48,
              imageToLoad: PanoImage30,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage29,
          spots: [
            {
              text: "Foyer Ende Gang",
              pitch: -5,
              yaw: -10,
              imageToLoad: PanoImage31,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage30,
          spots: [
            {
              text: "Grosser Saal Mitte",
              pitch: -5,
              yaw: 1,
              imageToLoad: PanoImage32,
              newHotspots: [],
            },
            {
              text: "Grosses Foyer",
              pitch: -5,
              yaw: 183,
              imageToLoad: PanoImage28,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage32,
          spots: [
            {
              text: "Grosser Saal Eingang",
              pitch: -5,
              yaw: -15,
              imageToLoad: PanoImage30,
              newHotspots: [],
            },
            {
              text: "Grosser Saal Vorne",
              pitch: -5,
              yaw: 165,
              imageToLoad: PanoImage35,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage35,
          spots: [
            {
              text: "Grosser Saal Mitte",
              pitch: 1,
              yaw: 1,
              imageToLoad: PanoImage32,
              newHotspots: [],
            },
            {
              text: "Grosser Saal Bühne",
              pitch: 1,
              yaw: 178,
              imageToLoad: PanoImage36,
              newHotspots: [],
            },
            {
              text: "Grosser Saal Balkon",
              pitch: 15,
              yaw: 1,
              imageToLoad: PanoImage38,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage36,
          spots: [
            {
              text: "Grosser Saal Orgel",
              pitch: 1,
              yaw: 1,
              imageToLoad: PanoImage35,
              newHotspots: [],
            },
            {
              text: "Grosser Saal Bühne",
              pitch: 5,
              yaw: 178,
              imageToLoad: PanoImage37,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage38,
          spots: [
            {
              text: "Grosser Saal Mitte",
              pitch: -15,
              yaw: 185,
              imageToLoad: PanoImage35,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage37,
          spots: [
            {
              text: "Grosser Saal Bühne",
              pitch: -15,
              yaw: 1,
              imageToLoad: PanoImage36,
              newHotspots: [],
            },
          ],
        },
        {
          name: PanoImage31,
          spots: [
            {
              text: "Gangende",
              pitch: -15,
              yaw: 180,
              imageToLoad: PanoImage39,
              newHotspots: [],
            },
          ],
        },
      ],
      imageButtons: [
        { label: "Großer Saal", image: PanoImage30 },
        { label: "Kleiner Saal", image: PanoImage25 },
        { label: "Großes Foyer", image: PanoImage17 },
        { label: "Kleines Foyer", image: PanoImage24 },
        { label: "Konferenzraum", image: PanoImage6 },
      ],
    };
  }

  handleChangeImage(newImage) {
    if (newImage) {
      this.setState(
        {
          currentImage: newImage,
        },
        () => {}
      );
    }
  }

  handleImageButtonClick(newImage) {
    console.log("Changing to image: ", newImage);
    if (newImage) {
      this.setState({ currentImage: newImage });
    }
  }

  render() {
    const { currentImage, hotspots, imageButtons } = this.state;
    const currentPanoramaData = hotspots.find(
      (panorama) => panorama.name === currentImage
    );
    return (
      <div className="flex flex-col p-8 bg-white">
        <div className="container px-6 mx-auto">
          <div className="w-full px-4 text-center justify-center space-y-4">
            <HighlightParagraph text="Erleben Sie die Meistersingerhalle aus eigener Perspektive!" />
            <HeadingParagraph text="Unsere 360 Grad Begehung" />
          </div>

          <div className="flex flex-col sm:flex-row justify-center my-8 space-y-2 sm:space-y-0 sm:space-x-4">
            {imageButtons.map((button, index) => (
              <CardButton
                key={index}
                label={button.label}
                type="button"
                clickHandler={() => this.handleChangeImage(button.image)}
              />
            ))}
          </div>
        </div>
        <div className="lg:flex lg:items-center">
          <Pannellum
            width="100%"
            height="800px"
            image={currentImage}
            key={currentImage}
            pitch={10}
            yaw={180}
            hfov={110}
            autoLoad
            onLoad={() => {}}
          >
            {currentPanoramaData &&
              currentPanoramaData.spots.map((hotspot, index) => (
                <Pannellum.Hotspot
                  key={hotspot.text || index}
                  type="custom"
                  pitch={hotspot.pitch}
                  yaw={hotspot.yaw}
                  handleClick={(evt, text) => {
                    console.log("Hotspot clicked", text);
                    this.handleChangeImage(hotspot.imageToLoad);
                  }}
                  CSSClass="custom-hotspot"
                >
                  <div className="tooltip">{hotspot.text}</div>
                </Pannellum.Hotspot>
              ))}
          </Pannellum>
        </div>
      </div>
    );
  }
}

export default Degree;
