import React, { Component } from "react";
import OrgelContainer from "../../../container/Halle/Orgel/Orgel";
import axios from "axios";
import AppURL from "../../../api/AppUrl";
import PageSlider from '../../../components/PageSlider'

class Orgel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrgelSliderData: [],
    };
  }
  componentDidMount() {
    axios
      .get(AppURL.OrgelSlider)
      .then((response) => {
        this.setState({ OrgelSliderData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div>
        <PageSlider data={this.state.OrgelSliderData} />
        <OrgelContainer formType="formOrgel" />
      </div>
    );
  }
}

export default Orgel;
