import React, { useState } from 'react';
import { MdVolumeUp } from 'react-icons/md';
import { useTextToSpeech } from './TextToSpeechContext';

const SpeakButton = ({ text }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { speakText } = useTextToSpeech(); // Use speakText from context
  if (!text) return null;
  return (
    <span
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{ position: 'relative', cursor: 'pointer', display: 'inline-block' }}
    >
      {text}
      {isHovering && (
        <MdVolumeUp
          onClick={() => speakText(text)}
          style={{
            marginLeft: '5px',
            cursor: 'pointer',
          }}
          aria-label="Text vorlesen."
        />
      )}
    </span>
  );
};

export default SpeakButton;