import logo from "../assets/logo.png";
import logo2 from "../assets/logo2.png";
import logoSVG from "../assets/LogoSVG.svg";
import bgIMG from "../assets/bgIMG.png";
import circle from "../assets/circle.svg";
import bgWhite from "../assets/bgWhite.png";
import MSH_Außenansicht_2 from "../assets/MSH_Außenansicht_2.jpg";
import MSH_Außenansicht_4 from "../assets/MSH_Außenansicht_4.jpg";
import MSH_Außenansicht_Daemmerung from "../assets/MSH_Außenansicht_Daemmerung.jpg";
import MSH_Außenansicht_3 from "../assets/MSH_Außenansicht_3.jpg";
import MSH_Außenansicht from "../assets/MSH_Außenansicht.jpg";
import MSH_BühneFlügel from "../assets/MSH_BühneFlügel.tif";
import MSH_Foyerklein from "../assets/MSH_FoyerKlein.jpg";
import MSH_Foyerklein_2 from "../assets/MSH_FoyerKlein_2.jpg";
import MSH_GroßerSaal from "../assets/MSH_GroßerSaal.jpg";
import MSH_GroßerSaal_2 from "../assets/MSH_GroßerSaal_2.jpg";
import MSH_GroßerSaal_3 from "../assets/MSH_GroßerSaal_3.jpg";
import MSH_GroßerSaal_4 from "../assets/MSH_GroßerSaal_4.jpg";
import MSH_GroßerSaal_5 from "../assets/MSH_GroßerSaal_5.jpg";
import MSH_GroßerSaal_6 from "../assets/MSH_GroßerSaal_6.jpg";
import MSH_GroßerSaal_7 from "../assets/MSH_GroßerSaal_7.jpg";
import MSH_GroßerSaal_8 from "../assets/MSH_GroßerSaal_8.jpg";
import MSH_GroßerSaal_9 from "../assets/MSH_GroßerSaal_9.jpg";
import MSH_GroßerSaal_10 from "../assets/MSH_GroßerSaal_10.jpg";
import MSH_Impression from "../assets/MSH_Impression.jpg";
import MSH_Impression_2 from "../assets/MSH_Impression_2.jpg";
import MSH_Impression_3 from "../assets/MSH_Impression_3.jpg";
import MSH_KleinerSaal from "../assets/MSH_KleinerSaal.jpg";
import MSH_Konferenzraum from "../assets/MSH_Konferenzraum.jpg";
import MSH_Studio from "../assets/MSH_Studio.JPG";
import MSHBeleuchtung from "../assets/MSHBeleuchtung.jpg";
import MSHBeleuchtung_1 from "../assets/MSHBeleuchtung_1.jpg";
import MSHBeleuchtung_2 from "../assets/MSHBeleuchtung_2.JPG";
import Saal from "../assets/Saal.jpg";
import bgEvents from "../assets/bgEvents.jpg";
import steeringWheel from "../assets/steering-wheel.png";
import clothesHanger from "../assets/clothes-hanger.png";
import catering from "../assets/catering.png";

export default {
  logo,
  logo2,
  logoSVG,
  bgIMG,
  circle,
  bgWhite,
  MSH_Außenansicht_2,
  MSH_Außenansicht_4,
  MSH_Außenansicht_Daemmerung,
  MSH_Außenansicht_3,
  MSH_Außenansicht,
  MSH_BühneFlügel,
  MSH_Foyerklein,
  MSH_Foyerklein_2,
  MSH_GroßerSaal,
  MSH_GroßerSaal_2,
  MSH_GroßerSaal_3,
  MSH_GroßerSaal_4,
  MSH_GroßerSaal_5,
  MSH_GroßerSaal_6,
  MSH_GroßerSaal_7,
  MSH_GroßerSaal_8,
  MSH_GroßerSaal_9,
  MSH_GroßerSaal_10,
  MSH_Impression,
  MSH_Impression_2,
  MSH_Impression_3,
  MSH_KleinerSaal,
  MSH_Konferenzraum,
  MSH_Studio,
  MSHBeleuchtung,
  MSHBeleuchtung_1,
  MSHBeleuchtung_2,
  Saal,
  bgEvents,
  steeringWheel,
  clothesHanger,
  catering,
};
