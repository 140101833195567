import React, { Component } from 'react'
import Slider from 'react-slick'
import SliderHeading from './Texts/SliderHeading'
import { FaPauseCircle, FaPlayCircle } from 'react-icons/fa'
import './PageSlider.css'

export class PageSlider extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.state = {
      isPlaying: true,
    }
  }

  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    const SliderData = this.props.data
    const showSliderControls = SliderData.length > 1

    const MyView = SliderData.map((SliderData, i) => {
      return (
        <div className="slider-item relative" key={i.toString()}>
          <img
            className="w-full h-screen object-cover"
            src={SliderData.slider_image}
            alt=""
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <SliderHeading text={SliderData.slider_heading} />
          </div>
        </div>
      )
    })

    return (
      <div className="slider-container">
        <Slider {...settings} ref={slider => (this.slider = slider)}>{MyView}</Slider>
        <div className={`flex absolute -mt-24 w-full justify-center ${showSliderControls ? '' : 'hidden'}`}>
          <div className="flex flex-row space-x-2 p-1">
            <button className="cursor-pointer border border-white rounded-full bg-white" onClick={ () => {
              this.play();
              this.setState({ isPlaying: true });
            } }>
              <FaPlayCircle fontSize={ 24 } color={this.state.isPlaying ? '#006eb8' : ''} />
            </button>
            <button className="cursor-pointer border border-white rounded-full bg-white" onClick={ () => {
              this.pause();
              this.setState({ isPlaying: false });
            } }>
              <FaPauseCircle fontSize={ 24 } color={this.state.isPlaying ? '' : '#006eb8'}/>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default PageSlider
