import React from 'react'

const HighlightParagraph = ({ text }) => {
  return (
    <span className="text-center font-bold text-2xl text-highlight block">
      {text}
    </span>
  )
}

export default HighlightParagraph