import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Spinner.scss";
import WebIcons from "../../components/Icons/WebIcons";
import { BiArrowToLeft } from "react-icons/bi";
import AppURL from "../../api/AppUrl"; // Update this path
import "./SingleNews.scss";
import { useParams } from "react-router-dom";

const SingleNews = () => {
  const [data, setData] = useState(null);
  const { news_code } = useParams();

  useEffect(() => {
    const fetchNewsDetails = () => {
      const url = AppURL.NewsDetails(news_code);
      axios
        .get(url)
        .then((response) => {
          setData(response.data[0]); // Since the API returns an array, we'll take the first element
        })
        .catch((error) => {
          // console.error("Error fetching news data:", error);
        });
    };

    fetchNewsDetails();
  }, [news_code, data]);

  const TRENNLINIE = "text-left rounded h-px";

  return (
    <>
      {data && (
        <div className="flex justify-center pb-14">
          <div className="max-w-4xl bg-white p-5 md:p-10 mt-32">
            <div className="BackArrow text-center mb-4">
              <a
                href="/Besuch"
                rel="norefferrer"
                className="flex items-center justify-center space-x-2"
              >
                <BiArrowToLeft className="fill-primary-1"/>
                <p className="font-bold text-primary-1">Zurück</p>
              </a>
            </div>
            <h1 className="headline text-2xl text-gray-900 font-bold md:text-4xl text-center mb-4">
              {data.news_title}
            </h1>
            <p className="text-sm text-gray-600 mb-4 text-center">
              Kategorie: {data.news_category}
            </p>
            <div className="md:px-10 pt-10 mb-4">
              <img
                className="rounded-2xl w-full object-cover"
                src={data.news_image}
                alt={data.news_title}
              />
            </div>
            <p className="text-justify px-10 mt-6 mb-4 leading-relaxed break-words">
              {data.news_text}
            </p>{" "}
            <div className={TRENNLINIE}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleNews;
