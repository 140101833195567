import React, { useEffect, useState, Component } from "react";
import { images } from "../../../constants";
import AppURL from "../../../api/AppUrl";
import axios from "axios";
import CardButton from '../../../components/CardButton'

class CTA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      InfoData: [],
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.HistorieCTA)
      .then((response) => {
        this.setState({ InfoData: response.data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  render() {
    const { InfoData} =
      this.state;

    return (
      <div>
        {InfoData && InfoData.map((data, index) => (
          <div key={index} className="py-16 bg-grey">
            <div className="container m-auto px-6 space-y-8 text-gray-500 md:px-12 lg:px-20">
              <div className="justify-center text-center gap-6 md:text-left md:flex lg:items-center  lg:gap-16">
                <div className="order-last mb-6 space-y-6 md:mb-0 md:w-6/12 lg:w-6/12">
                  <h1 className="text-4xl text-gray-700 font-bold md:text-5xl">
                    {data.headline}
                  </h1>
                  <p className="text-lg">{data.paragraph}</p>
                  <div className="flex flex-row-reverse flex-wrap justify-center gap-4 md:gap-6 md:justify-end">
                    <a href="/Veranstalter">
                      <CardButton label="Veranstalter" />
                    </a>
                    <a href="/Halle#Degree">
                      <CardButton label="360° Ansicht" />
                    </a>
                  </div>
                </div>
                <div className="grid grid-cols-5 grid-rows-4 gap-4 md:w-5/12 lg:w-6/12">
                  <div className="col-span-2 row-span-4">
                    <img
                      src={data.image_left}
                      className="rounded-full"
                      width="640"
                      height="960"
                      alt="shoes"
                      loading="lazy"
                    />
                  </div>
                  <div className="col-span-2 row-span-2">
                    <img
                      src={data.image_right}
                      className="w-full h-full object-cover object-top rounded-xl"
                      width="640"
                      height="640"
                      alt="shoe"
                      loading="lazy"
                    />
                  </div>
                  <div className="col-span-3 row-span-3">
                    <img
                      src={data.image_bottom}
                      className="w-full h-full object-cover object-top rounded-xl"
                      width="640"
                      height="427"
                      alt="shoes"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default CTA;
