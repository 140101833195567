import React, {  Component } from "react";
import backgroundImage from "../../assets/MSH_Außenansicht_4.jpg";

class Legal extends Component {
  render() {
    return (
      <div>
        <section
          className="text-white py-40 lg:h-1/2 lg:items-center bg-cover bg-center"
          style={{
            backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.1), rgba(128, 128, 128, 0.3)), url(${backgroundImage})`,
          }}
        >
          <div className="mx-auto max-w-screen-xl px-4">
            {/*             <div className="mx-auto max-w-3xl text-center">
              <h1 className="text-white bg-gradient-to-r from-red-300 via-red-500 to-gray-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
                Anreise
              </h1>
              <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
                Hier finden Sie alle Informationen zur Anreise
              </p>
            </div> */}
          </div>
        </section>
        <section className="bg-gray-100 py-8">
          <div className="sm:container mx-auto flex justify-center">
            {/* Main Content */}
            <div className="max-w-3xl">
              <h2 className="text-2xl font-bold mb-4">Rechtliches</h2>

              <p className="mb-4">Fotografie</p>

              <p className="mb-4"></p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Legal;
