import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Spinner.scss";
import Venue from "../../components/Cards/Venue";
import Moment from "react-moment";
import Collapsible from "../../components/Collapsible/Collapsible";
import WebIcons from "../../components/Icons/WebIcons";
import { BiArrowToLeft } from "react-icons/bi";
import CardButton from '../../components/CardButton'

export default function SingleEvent() {
  const { ID, Date } = useParams();
  const [data, setData] = useState(null);
  const [DateIndex, setDate] = useState(0);
  const TRENNLINIE = "text-left bg-primary-2 rounded h-px";
  const TRENNLINIEGRAU = "text-left bg-gray-200 rounded h-px";
  const url =
    "https://vk.nuernberg.de/export.php?" +
    "&USERNAME=meistersingerhalle&password=Bierstube" +
    "&ALLETERMINE=1" +
    "&AUSGABEFELDER=TITEL,KURZTITEL,UNTERTITEL,BESCHREIBUNG,INTERNETSEITE,FACEBOOK,TWITTER,VIDEO," +
    "VVKLINK,VVKDATUM,VVKUEBLICH,VVKINFOS,KINDER,FAMILIEN,INTERKULTUR,ALTER_VON,ALTER_BIS," +
    "LETZTEAENDERUNG,ORT,VERANSTALTER,DATUM,ERSTERTERMIN,KATEGORIE,ART,BILD,BILDER,PREISE,TAGS,PRIVATETAGS,LINKS" +
    "&START_DATUM=" +
    Today +
    "&VERANSTALTUNGID1=" +
    ID;
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(url);
      setData(result.data?.VERANSTALTUNGEN[0]);
    };
    fetchData();
    goToTop();
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {data != null && (
        <div className="flex justify-center pb-14 ">
          <div className="max-w-4xl bg-white py-10 md:p-10">
            <div>
              <div className="flex justify-center">
                <div className="grid grid-rows-8">
                  <div className="text-black">
                    <a href="/Veranstaltungen" rel="norefferrer" className="flex flex-row">
                      <BiArrowToLeft className="text-[24px]"/>
                      <p className="font-bold text-black mb-5">Zurück</p>
                    </a>
                  </div>
                  <h1 className="headline text-2xl text-gray-900 font-bold md:text-4xl">
                    {data.TITEL}
                  </h1>
                  <p className="text-justify italic text-gray-400 pb-5 pt-10 px-20">
                    {data.UNTERTITEL}
                  </p>
                  <div className="md:px-10 pb-10">
                    <img
                      className="rounded-2xl w-full"
                      src={data.BILD[0].sn + "?groesse=2"}
                    ></img>
                  </div>
                  
                  <p className="text-justify px-10">{data.BESCHREIBUNG}</p>
                  <div className="text-left mt-10 outline-1 px-10 rounded">
                    <div>
                      <div className="">
                        {/* Datum, Uhrzeit, Abgesagt */}
                        <Collapsible
                          className="mx-0"
                          label={
                            <div className="text-left bg-primary-2 rounded">
                              <div>
                                <div className="flex justify-around grid md:grid-cols-2 pl-1 pr-10 ">
                                  <p
                                    className={
                                      "col-span-1 text-white font-semibold py-3"
                                    }
                                  >
                                    <Moment
                                      locale="DE"
                                      date={GetDate(ClosestDate(data))}
                                      format="dddd,  DD. MMMM YYYY"
                                    ></Moment>
                                  </p>
                                  <p
                                    className={
                                      "col-span-1 text-white font-semibold py-3"
                                    }
                                  >
                                    Beginnt um{" "}
                                    {GetTime(ClosestDate(data)) + " Uhr"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          }
                          labelOpen={
                            <div className="text-left bg-primary-2 rounded px-10">
                              <p className="text-white font-semibold py-3">
                                Alle Termine
                              </p>
                            </div>
                          }
                        >
                          {Object.getOwnPropertyNames(data.ALLETERMINE).map(
                            (item) => (
                              <>
                                <div>
                                  {GetDateFULL(
                                    item,
                                    false,
                                    "text-left",
                                    "text-left",
                                    "text-right"
                                  )}
                                </div>
                              </>
                            )
                          )}{" "}
                          <div className={TRENNLINIE + " mt-5"}></div>
                        </Collapsible>

                        {/*
                        { GetDate(Array.from(Object.values(data.DATUM[0]))[DateIndex]) }                 
                        */}
                      </div>

                      <div className="text-left font-semibold text-black-600 my-5 grid md:grid-cols-2">
                        {/* Ort*/}

                        <div className="pb-5 md:col-span-1">
                          <p className="text-left">VERANSTALTUNGSORT</p>
                        </div>

                        <div className="row-span-1 md:col-span-1 font-normal ">
                          <p className=" text-left">{data.ORTSNAMEKOMPLETT}</p>
                          <p className=" text-left">{data.ORTSSTRASSENR}</p>
                          <p className=" text-left">
                            {data.ORTSPLZ + " " + data.ORTSORT}
                          </p>
                          <p className=" text-left"> {data.ORTSTELEFON}</p>
                        </div>
                      </div>
                      <div className={TRENNLINIE}></div>
                      {data.ALTER_BIS === "0" && data.ALTER_VON === "0" ? (
                        <></>
                      ) : (
                        <>
                          <div className="text-left font-semibold text-black-600 my-5">
                            {/* Alter */}
                            <div className="grid md:grid-cols-2">
                              <div className="pb-5 col-span-1 text-left">ALTER</div>
                              <div className="col-span-1 text-right">
                                {data.ALTER_BIS === "0" ? (
                                  <p className="text-left font-normal">
                                    Ab {data.ALTER_VON}
                                  </p>
                                ) : (
                                  <>
                                    <p className="text-left font-normal">
                                      Von {data.ALTER_VON} bis {data.ALTER_BIS}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={TRENNLINIE}></div>
                        </>
                      )}
                      <div className="grid md:grid-cols-2 my-5">
                        <div className="col-span-1 text-left font-semibold text-black-600 ">
                          <div className="pb-5 text-left">PREISE</div>
                        </div>
                        {/* Preise */}
                        {data.EINTRITT === "1" ? (
                          <>
                            <p className="text-left font-normal">Eintritt frei</p>
                          </>
                        ) : (
                          <>
                            <div className="col-span-1 text-left justify-items-start">
                              {FILTER(
                                Array.from(Object.values(data.PREISE))
                              ).map((item) => (
                                <>
                                  <div className="mr-10">
                                    {FILTER(
                                      Array.from(Object.values(data.PREISE))
                                    ).length > 1 ? (
                                      <p className="text-left font-semibold text-black-600 my-3">
                                        {item[0].KAT}. Preiskategorie:
                                      </p>
                                    ) : (
                                      <></>
                                    )}

                                    <div className="">
                                      {item.map((entry) => (
                                        <>
                                          {entry.ERM === 0 && (
                                            <p className="text-left">
                                              {entry.ARTNAME}:{" "}
                                              {entry.VAL.toLocaleString(
                                                "de-DE",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              )}
                                            </p>
                                          )}
                                          {entry.ERM === 1 && (
                                            <>
                                              <p className="text-left">
                                                {entry.ARTNAME} (ermäßigt):{" "}
                                                {entry.VAL.toLocaleString(
                                                  "de-DE",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </p>
                                              <p className="text-left text-gray-400 italic">
                                                {entry.INFO}
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                  
                                  {item[0].KAT <
                                  FILTER(Array.from(Object.values(data.PREISE)))
                                    .length ? (
                                    <>
                                      {" "}
                                      <div
                                        className={TRENNLINIEGRAU + " mt-5"}
                                      ></div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                      
                      <div className={TRENNLINIE}></div>
                      <div className="grid md:grid-cols-2 my-5">
                        <div className="pb-5 col-span-1 text-left font-semibold text-black-600 ">
                          {/* Veranstalter */}
                          VERANSTALTER
                        </div>
                        <div className="col-span-1">
                          <div className="text-left font-semibold">
                            {data.VERANSTALTER[0].VERANSTALTERNAME}
                          </div>
                          <div className="text-left font-normal">
                            {data.VERANSTALTER[0].VERANSTALTERSTRASSENR}
                          </div>
                          <div className="text-left font-normal">
                            {data.VERANSTALTER[0].VERANSTALTERPLZ}{" "}
                            {data.VERANSTALTER[0].VERANSTALTERORT}
                          </div>
                          <div className="text-left font-normal">
                            E-Mail: {data.VERANSTALTER[0].VERANSTALTEREMAIL}
                          </div>
                          <div className="text-left font-normal underline">
                            <a
                              href={data.VERANSTALTER[0].VERANSTALTERINTERNET}
                              target="_blank" rel="noreferrer"
                            >
                              {data.VERANSTALTER[0].VERANSTALTERINTERNET}
                            </a>
                          </div>
                          <div className="text-left font-normal">
                            Tel.: {data.VERANSTALTER[0].VERANSTALTERTELEFON}
                          </div>
                        </div>
                      </div>
                      <div className={TRENNLINIE}></div>
                      <div className="grid md:grid-cols-2 my-5">
                        <div className="pb-5 col-span-1 text-left font-semibold text-black-600 ">
                          {/* Veranstalter */}
                          ALTERSEMPFEHLUNG
                        </div>
                        <div className="col-span-1">
                          <div className="text-left font-semibold">
                            {/*data.VERANSTALTER[0].VERANSTALTERNAME (altersempfehlung)*/}
                          </div>
                          
                        </div>
                      </div>
                      <div className={TRENNLINIE}></div>
                      <div className="text-left font-semibold text-black-600 my-5 grid md:grid-cols-2">
                        {/* WeitereInfos */}
                        <div className="pb-5 col-span-1 text-left">
                          WEITERE INFORMATIONEN
                        </div>

                        <div className="col-span-1">
                        <p>Hier finden Sie weitere Informationen zur Veranstaltung:</p>
                          <WebIcons
                            Links={data.LINKS}
                            itemAddition="text-primary-1"
                          ></WebIcons>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {data === null && (
        <div className="container px-5 pt-24 pb-80 mx-auto">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
function GetDateFULL(item, raw, dayOverride, dateOverride, timeOverride) {
  var dateName = raw ? item : item.E;
  return (
    <>
      <div className="flex justify-around grid grid-cols-4 px-10 ">
        <p className={"col-span-1 " + dayOverride}>
          <Moment locale="DE" date={GetDate(item)} format="dddd"></Moment>
        </p>
        <p className={"col-span-1 " + dateOverride}>
          <Moment locale="DE" date={GetDate(item)} format="DD. MMMM"></Moment>
        </p>
        <p className={"col-span-1 " + dateOverride}>
          <Moment locale="DE" date={GetDate(item)} format="YYYY"></Moment>
        </p>
        <p className={"col-span-1 " + timeOverride}>
          Beginnt um {GetTime(item) + " Uhr"}
        </p>
      </div>
    </>
  );
}
function GetDate(item) {
  var dateName;
  dateName = item;
  return dateName;
}

function GetTime(item) {
  var dateName;
  dateName = item;
  dateName = dateName.split("T")[1];
  if (item.EIN === undefined || item.EIN === null || item.length === 0) {
    return dateName;
  }
  return item.EIN;
}

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function FILTER(test) {
  var a = test;
  a = test[0];
  var b = groupBy(test, "KAT");
  var c = Array.from(Object.values(b));
  return c;
}

const Today = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

const ClosestDate = (data) => {
  var item = Object.getOwnPropertyNames(data.ALLETERMINE)[0];
  return item;
};
