import React from "react";
import backgroundImage from "../../assets/MSH_Außenansicht_4.jpg";

function Garderobe() {
  return (
    <div>
      <section
        className="text-white py-40 lg:h-1/2 lg:items-center bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.1), rgba(128, 128, 128, 0.3)), url(${backgroundImage})`,
        }}
      >
        <div className="mx-auto max-w-screen-xl px-4">
          {/*           <div className="mx-auto max-w-3xl text-center">
            <h1 className="bg-gradient-to-r from-red-300 via-red-500 to-gray-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
              Garderobe
            </h1>

            <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
              Hier finden Sie alle Informationen zur Garderobe in der
              Meistersingerhalle
            </p>
          </div> */}
        </div>
      </section>
      <section className="bg-gray-100 py-8">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-4">
            {/* Text Content */}
            <div>
              <h2 className="text-2xl font-bold mb-4 md:text-center">
                Garderobenpflicht
              </h2>
              <p className="mb-4">
                Für Veranstaltungen in der Meistersingerhalle besteht
                Garderobenpflicht. Überbekleidung, Schirme, Stöcke mit Ausnahme
                von Stöcken Gehbehinderter, sind aus Sicherheitsgründen an der
                Garderobe abzugeben.
              </p>
            </div>

            {/* Image Content */}
            <div className="order-first md:order-none">
              {/* Replace 'path-to-your-image.jpg' with the actual path to your image */}
              <img
                src={backgroundImage}
                alt="Meistersingerhalle Garderobe"
                className="w-full h-auto object-cover rounded-xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Garderobe;
