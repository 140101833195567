import React, { Component } from 'react'
import './KleinerSaal.scss'
import AppURL from '../../../api/AppUrl'
import axios from 'axios'
import MSH_Außenansicht_2 from '../../../assets/MSH_Außenansicht_2.jpg'
import DownloadSection from '../../../components/DownloadSection'
import Imagecards from '../../../components/imagecards/imagecards'
import ImageSliderWithText from '../../../components/HallSmallSlider/ImageSliderWithTextLeft'
import Buehnenbeleuchtung from '../../../assets/PDFFiles/Kleiner Saal/kleinersaal_buehnenbeleuchtung.pdf'
import SaalUebersicht from '../../../assets/PDFFiles/Kleiner Saal/kapazitaet_cd.pdf'
import SaalStromAnschluesse from '../../../assets/PDFFiles/Kleiner Saal/kleinersaal_stromanschluesse.pdf'
import FoyerUebersicht from '../../../assets/PDFFiles/Kleiner Saal/kleinesfoyer_uebersicht.pdf'
import FoyerStromAnschluesse from '../../../assets/PDFFiles/Kleiner Saal/kleinesfoyer_stromanschluesse.pdf'
import Kapazitaeten from '../../../assets/PDFFiles/Kleiner Saal/kapazitaet_cd.pdf'
import GarderobenHalleStromAnschluesse
  from '../../../assets/PDFFiles/Kleiner Saal/kleinersaal_garderobenhalle_stromanschluesse.pdf'
import InfoColumns from '../../../components/InfoColumns/InfoColumns'
import image_1 from '../../../assets/Kapazitaeten/KS-Bankett1.png'
import image_2 from '../../../assets/Kapazitaeten/KS-Bankett2.png'
import image_3 from '../../../assets/Kapazitaeten/KS-Parlament.png'
import image_4 from '../../../assets/Kapazitaeten/KS-Parlamentschmal.png'
import image_5 from '../../../assets/Kapazitaeten/KS-Reihe.png'
import CardButton from '../../../components/CardButton'
import HighlightParagraph from '../../../components/Texts/HighlightParagraph'
import HeadingParagraph from '../../../components/Texts/HeadingParagraph'
import Paragraph from '../../../components/Texts/Paragraph'
import ContactSection from '../../../components/ContactSection'

const downloadSectionConfig = {
  heading: 'Weitere Informationen zum Herunterladen',
  links: [
    {
      url: Buehnenbeleuchtung,
      text: 'Bühnenbeleuchtung',
      fileName: 'MSH - Kleiner Saal - Bühnenbeleuchtung',
    },
    {
      url: SaalUebersicht,
      text: 'Saal Übersicht',
      fileName: 'MSH - Kleiner Saal - Saal Übersicht',
    },
    {
      url: SaalStromAnschluesse,
      text: 'Saal Stromanschlüsse',
      fileName: 'MSH - Kleiner Saal - Saal Stromanschlüsse',
    },

    {
      url: FoyerUebersicht,
      text: 'Foyer Übersicht',
      fileName: 'MSH - Kleiner Saal - Foyer Übersicht',
    },
    {
      url: FoyerStromAnschluesse,
      text: 'Foyer Stromanschlüsse',
      fileName: 'MSH - Kleiner Saal - Foyer Stromanschlüsse',
    },
    {
      url: Kapazitaeten,
      text: 'Kapazitäten',
      fileName: 'MSH - Kleiner Saal - Kapazitäten',
    },
    {
      url: GarderobenHalleStromAnschluesse,
      text: 'Garderoben Strom Anschlüsse',
      fileName: 'MSH - Kleiner Saal - Garderoben Strom Anschlüss',
    },
  ],
}

class KleinerSaal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      formData: {
        name: '',
        email: '',
        company: '',
        subject: '',
        message: '',
      },
      isLoading: false,
      InfoData: [],
      CardsData: [],
      SmallSliderData: [],
      GoodToKnowData: [],
    }
  }

  componentDidMount () {
    const storedFormData = JSON.parse(localStorage.getItem('formInformation'))
    if (storedFormData) {
      this.setState({ formData: storedFormData })
    }
    axios
      .get(AppURL.SmallSlider('kleinersaal'))
      .then((response) => {
        this.setState({ SmallSliderData: response.data })
      })
      .catch((error) => {
        console.log(error)
      })
    axios
      .get(AppURL.GoodToKnow('kleinersaal'))
      .then((response) => {
        this.setState({ GoodToKnowData: response.data })
      })
      .catch((error) => {
        console.log(error)
      })
    axios
      .get(AppURL.KleinerInfo)
      .then((response) => {
        this.setState({ InfoData: response.data })
      })
      .catch((error) => {
        console.log(error)
      })
    axios
      .get(AppURL.KleinerCards)
      .then((response) => {
        this.setState({ CardsData: response.data })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()

    // Get form data from state
    const { name, email, company, subject, message } = this.state.formData

    // Get data from localStorage
    const localStorageData = localStorage.getItem('formInformation') ?? '{}' // Replace 'myData' with the key for your stored data

    // Create the request body
    const requestBody = {
      formType: 'small',
      name,
      email,
      company,
      subject,
      message,
      localStorageData, // Include the localStorage data in the request body
    }

    // Send the form data to the backend
    fetch('/submit-form-hall', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the backend
        console.log(data)
        // Reset the form and clear localStorage data if needed
        this.setState({
          formData: {
            name: '',
            email: '',
            company: '',
            subject: '',
            message: '',
          },
        })
        localStorage.removeItem('formInformation') // Replace 'myData' with the key for your stored data
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error:', error)
      })
  }

  render () {
    const { formData, InfoData, CardsData } = this.state
    const images = [image_1, image_2, image_3, image_4, image_5]

    return (
      <div>
        <section className="bg-white py-8">
          <div className="container px-6 mx-auto">
            {/* InfoData Section */}
            <div className="px-6">
              {InfoData &&
                InfoData.map((data) => (
                  <div key={data.id} className="flex flex-col space-y-4">
                    <HighlightParagraph text={data.headline}/>
                    <HeadingParagraph text={data.subtitle}/>
                    <Paragraph text={data.paragraph}/>
                  </div>
                ))}
            </div>
            {/* ImageSliderWithText Section */}
            <div className="px-6 bg-white rounded-lg">
              {this.state.isLoading ? (
                <p>Lade...</p>
              ) : (
                <ImageSliderWithText
                  slides={(this.state.SmallSliderData ?? []).map((item) => ({
                    image: item.slider_image,
                    text: item.slider_paragraph,
                  }))}
                  className="mt-6"
                />
              )}
            </div>

            <div className="mt-12 py-8 bg-stone-300 shadow-lg rounded-lg flex flex-col space-y-8">
              <HeadingParagraph text="Gut zu wissen"/>
              <InfoColumns data={this.state.GoodToKnowData}/>
            </div>
          </div>
        </section>

        <section className="py-8 bg-gray-100">
          {/* Imagecards Section */}
          <div className="flex flex-col space-y-8 mx-8">
            <HeadingParagraph text="Kapazitäten"/>
            <Imagecards images={images} className="mx-4"/>
          </div>
          {/* CardsData Section */}
          <section className="px-6 rounded-lg mt-8">
            <div className="grid grid-cols-2 lg:grid-cols-3">
              {CardsData &&
                CardsData.map((card, index) => (
                  <div className="flex flex-wrap w-full" key={index}>
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block object-cover object-center w-full h-full rounded-lg"
                        src={card.image}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </section>
          {/* InfoColumns Section */}
        </section>

        <section className="py-8 bg-white">
          <ContactSection
            salutation="Ansprechpartnerin"
            contactPersonName="Sonja Flierl"
            contactPersonDescription="Veranstaltungen im Kleinen Saal & Foyer"
            contactPersonPhoneNumber="0911 / 231 - 80 10"
            contactPersonEmail="sonja.flierl@stadt-nuernberg.de"
            imageSrc={MSH_Außenansicht_2}
            formData={formData}
            submitFunction={this.handleSubmit}
            changeFunction={this.handleChange}
          />
        </section>

        <section className="py-8 bg-gray-100">
          <DownloadSection config={downloadSectionConfig}/>
        </section>
      </div>
    )
  }
}

export default KleinerSaal
