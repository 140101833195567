import React from "react"
import {
  BsThreeDots,
  BsHouseDoorFill,
  BsFacebook,
  BsInstagram,
  BsGlobe,
} from "react-icons/bs"

export default function WebIcons({Links, wrapperAddition, itemAddition}) {
  return (
    <div>
      <div className={"row-span-1 flex flex-nowrap " + wrapperAddition}>
        {Links != null &&
          Links.map((item) => (
            <>
              {GetIcon(
                Links,
                item,
                "pt-1 pr-5 font-bold text-xl mb-0 justify-center " +
                  itemAddition
              )}
            </>
          ))}
      </div>
    </div>
  )
}

function GetIcon(link, linkName, classValue) {
  var name = Object.getOwnPropertyNames(linkName)
  switch (name[0]) {
    case "webseite":
      return (
        <a className={classValue} target="_blank" href={Object.values(linkName)} rel="noreferrer">
          <BsHouseDoorFill />
        </a>
      )
    case "webseitereihe":
      return (
        <a className={classValue} target="_blank" href={Object.values(linkName)} rel="noreferrer">
          <BsGlobe />
        </a>
      )
    case "facebook":
      return (
        <a className={classValue} target="_blank" href={Object.values(linkName)} rel="noreferrer">
          <BsFacebook />
        </a>
      )
    case "instagram":
      return (
        <a className={classValue} target="_blank" href={Object.values(linkName)} rel="noreferrer">
          <BsInstagram />
        </a>
      )
    case "twitter":
      return (
        <a className={classValue} target="_blank" href={Object.values(linkName)} rel="noreferrer">
          <BsThreeDots />
        </a>
      )
    case "video":
      return (
        <a className={classValue} target="_blank" href={Object.values(linkName)} rel="noreferrer">
          <BsThreeDots />
        </a>
      )
    case "anmeldung":
      return (
        <a className={classValue} target="_blank" href={Object.values(linkName)} rel="noreferrer">
          <BsThreeDots />
        </a>
      )
    case "stream":
      return (
        <a className={classValue} target="_blank" href={Object.values(linkName)} rel="noreferrer">
          <BsThreeDots />
        </a>
      )
    case "sonstige":
      return (
        <a className={classValue} target="_blank" href={Object.values(linkName)} rel="noreferrer">
          <BsThreeDots />
        </a>
      )
    default:
      return <></>
  }
}
