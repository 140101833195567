import React, { Component } from 'react'
import Moment from 'react-moment'
import moment from 'moment'
import { Link } from 'react-router-dom'
import axios from 'axios'
/* import "./LandingProgram.scss"; */
import './Spinner.scss'
import AppURL from '../../api/AppUrl'
import SliderLoading from '../../components/PlaceHolder/SliderLoading'
import ProgramCards from '../../components/ProgramCards'
import CardButton from '../../components/CardButton'
import HeadingParagraph from '../../components/Texts/HeadingParagraph'
import HeadingSmallParagraph from '../../components/Texts/HeadingSmallParagraph'
import Paragraph from '../../components/Texts/Paragraph'

class LandingProgram extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: null,
      dataTwo: null,
      HervorgehobenData: [],
      isLoading: '',
      mainDiv: 'd-none',
    }
  }

  componentDidMount () {
    this.fetchData()
    axios
      .get(AppURL.HervorgehobenVer)
      .then((response) => {
        this.setState({
          HervorgehobenData: response.data,
          isLoading: 'd-none',
          mainDiv: '',
        })
      })
      .catch((error) => {})
  }

  fetchData = async () => {
    const today = new Date()
    const nextDay = new Date(today)
    nextDay.setDate(today.getDate() + 1)
    const todayFormatted = this.formatDate(today)
    const nextDayFormatted = this.formatDate(nextDay)

    const url =
      'https://vk.nuernberg.de/export.php?' +
      'USERNAME=meistersingerhalle&password=Bierstube' +
      '&TYP=SUCHE&FORMAT=JSON&' +
      '&START_DATUM=' +
      todayFormatted +
      '&MAX_LIMIT=1' +
      '&AUSGABEFELDER=TITEL,UNTERTITEL,DATUM,BESCHREIBUNG,BILD,KATEGORIE,ORT,LINKS,VVKLINK'

    const urlTwo =
      'https://vk.nuernberg.de/export.php?' +
      'USERNAME=meistersingerhalle&password=Bierstube' +
      '&TYP=SUCHE&FORMAT=JSON&' +
      '&START_DATUM=' +
      nextDayFormatted +
      '&MAX_LIMIT=1' +
      '&AUSGABEFELDER=TITEL,UNTERTITEL,DATUM,BESCHREIBUNG,BILD,KATEGORIE,ORT,LINKS,VVKLINK'
    const headers = {
      'Content-Type': 'application/json',
    }

    try {
      const response = await axios(url, { headers: headers })
      const responseTwo = await axios(urlTwo)
      this.setState({
        data: response.data,
        dataTwo: responseTwo.data,
      })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  formatDate (date) {
    const yyyy = date.getFullYear()
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const dd = String(date.getDate()).padStart(2, '0')
    return `${yyyy}-${mm}-${dd}`
  }

  parseDate(date) {
    return moment(date, 'DD.MM.YYYY')
  }

  render () {
    const { data, dataTwo } = this.state

    return (
      <>
        <SliderLoading isLoading={this.state.isLoading}/>
        <div className={this.state.mainDiv}>
          <div className="py-8 bg-white space-y-2">
            <HeadingParagraph text="Wer wir sind"/>
            <HeadingSmallParagraph heading="Unterüberschrift" />
            <Paragraph text="Paragraph-B"/>
          </div>
          <div className="py-8 bg-white space-y-2">
            <HeadingParagraph text="Aktuelle Veranstaltungen"/>

            <div className="sm:container m-auto text-gray-600 justify-center pt-8">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mx-auto place-content-center">
                <div className="">
                  {data != null && <ProgramCards data={data}/>}
                </div>
                {data === null && (
                  <div className="container px-5 pt-24 pb-80 ">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                {this.state.HervorgehobenData.length > 0 && (
                  <div className="w-full h-full flex justify-center">
                    <div className="max-w-lg ">
                      {this.state.HervorgehobenData.map((item) => (
                        <div
                          key={item.id}
                          className="overflow-hidden rounded-xl bg-white shadow-md"
                        >
                          <img
                            src={item.herv_image}
                            alt="Vorgehobenes Event"
                            className="h-[300px] w-[500px] object-cover"
                          />
                          <div className="px-5 py-3">
                            <p className="event--titel font-bold text-medium mb-5 text-gray-700 text-center">
                              {item.herv_heading}
                            </p>
                            <p className="text-medium text-gray-700 text-center">
                              <Moment
                                locale="de"
                                date={this.parseDate(item.herv_datum)}
                                format="dddd, DD. MMMM YYYY"
                              />
                            </p>
                            <p className="text-medium mb-5 text-gray-700 text-center">
                              Beginn {item.herv_einlass} Uhr
                            </p>
                            <a href={item.herv_button_link}>
                              <CardButton
                                label="Zur Veranstaltung"
                                fullWidth={true}
                              />
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                )}
                <div className="">
                  {dataTwo != null && <ProgramCards data={dataTwo}/>}
                </div>
                {data === null && (
                  <div className="container px-5 pt-24 pb-80 mx-auto">
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  getDate (item) {
    const dateName = Object.getOwnPropertyNames(item.DATUM[0])[0]
    return dateName
  }
}

export default LandingProgram
