import React, { useState, useEffect  } from 'react'
import { images } from '../../constants'
import { menuNames } from './MenuNames'
import Item from './Item'
import { HiMenuAlt4, HiX } from 'react-icons/hi'
import { motion } from 'framer-motion'
import StadtNuernbergRoterHintergrundAufWeiss from '../../assets/StadtNuernbergRoterHintergrundAufWeiss.svg'

const NavBar = () => {
  const [isSideMenuVisible, setSideMenuVisible] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const motionVariants = {
    open: { x: 0 },
    closed: { x: "100vw" },
  };

  const backgroundClasses = scrolled ? 'bg-white/50' : 'bg-white/25'

  return (
    <nav className={`fixed z-10 w-full h-[90px] flex items-center shadow-md backdrop-blur-[4px] ${backgroundClasses}`}>
      <a href="/">
        <img src={images.logo} alt="Meistersingerhalle Nürnberg Logo" className="h-[75px] w-auto ml-4"/>
      </a>

      <div className="hidden lg:flex flex-row justify-center align-items-center list-none mx-auto space-x-1 xl:space-x-4">
        {menuNames.map((item) => (
          <Item item={item} key={item.title} />
          
        ))}
      </div>

      <div className="hidden w-[0px] lg:block lg:w-[150px] xl:w-[200px]">
        <img
          className="hidden w-[0px] lg:block lg:w-[150px] xl:w-[200px] bg-red text-white absolute right-0 top-0 mt-4 mr-10"
          src={StadtNuernbergRoterHintergrundAufWeiss}
          alt="Stadt Nürnberg Logo"
        />
      </div>

      <div
        className="flex lg:hidden ml-auto mr-8 rounded-full p-1 text-white bg-primary-2 hover:bg-primary-2 cursor-pointer"
        onClick={() => setSideMenuVisible(true)}
      >
        <HiMenuAlt4 fontSize={24} />
      </div>

      {
        <motion.div
          className="z-10 lg:hidden fixed top-0 right-0"
          animate={isSideMenuVisible ? "open" : "closed"}
          variants={motionVariants}
          transition={{ duration: 0.55, ease: "easeInOut" }}
          initial={false}
        >
          <div className="absolute z-20 bg-white right-0 w-[300px] sm:w-[400px] flex flex-col p-7 h-[100vh]">
            <div className="cursor-pointer ml-auto text-black hover:bg-primary-2 hover:text-white rounded-full p-1">
              <HiX onClick={() => setSideMenuVisible(false)} fontSize={34} />
            </div>

            <div className="flex flex-col space-y-8" onClick={() => setSideMenuVisible(false)}>
              {menuNames.map((item) => (
                <Item item={item} key={item.title}  className="absolute"/>
              ))}
              <div className="flex-grow" />
            </div>
          </div>
        </motion.div>
      }
    </nav>
  )
}

export default NavBar
