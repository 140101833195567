import React, { useState } from "react";
import {
  BsThreeDots,
  BsHouseDoorFill,
  BsFacebook,
  BsInstagram,
  BsGlobe,
  BsBookmarksFill,
} from "react-icons/bs";
import WebIcons from "./Icons/WebIcons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/de";
import SingleEvent, { setDetailID } from "../container/Events/SingleEvent";
import CardButton from "./CardButton";

export default function EventCard({
  Title,
  Image,
  Location,
  ImageName,
  Description,
  Date,
  Entry,
  Subtitle,
  Links,
  VVKlink,
  ID,
  IsCancelled,
}) {
  const [data, setData] = useState({ ID: -1 });
  return (
    <>
      <div className="flex flex-col rounded-lg overflow-hidden shadow-lg pb-4 h-full">
        <Link to={`/Veranstaltungen/${ID}/${Date}`} className="">
          <img
            src={Image}
            className="object-cover object-top aspect-[4/3] w-full h-full"
            alt={ImageName}
          />
        </Link>

        <div className="flex flex-col px-6 w-full space-y-4 h-full">
          <Link to={"/Veranstaltungen/" + ID + "/" + Date}>
            {IsCancelled != null && IsCancelled && (
              <>
                <p className="row-span-1 text-red font-bold mt-4">ABGESAGT</p>
              </>
            )}

            <p
              className={`text-center font-semibold text-xl text-gray-700 my-3 ${
                IsCancelled ? "line-through" : ""
              }`}
            >
              {Title}
            </p>

            <p className="text-center font-semibold text-md text-gray-600">
              <Moment locale="DE" date={Date} format="dddd, DD. MMMM YYYY" />
            </p>

            <div className="flex flex-row space-x-4 w-full justify-center">
              <p className="row-span-1 font-semibold text-gray-500 text-center text-sm md:text-md">
                {Location} - Einlass:
                {" " + Entry + " "}
                Uhr
              </p>
            </div>
          </Link>

          <div className="flex-grow" />

          <div className="flex flex-row sm:flex-col lg:flex-row space-y-0 sm:space-y-2 lg:space-y-0 space-x-2 sm:space-x-0 lg:space-x-2 mt-auto mb-5 ">
            {VVKlink != null && (
              <div className="row-span-1 col-span-1 flex grow flex-nowrap content-center w-full justify-center h-[56px]">
                {IsCancelled ? (
                  <CardButton label="Zum Vorverkauf" fullWidth={false} customClasses="h-[56px]"/>
                ) : (
                  <a href={VVKlink} target="" rel="noreferrer">
                    <CardButton label="Zum Vorverkauf" fullWidth={false} customClasses="h-[56px]" />
                  </a>
                )}
              </div>
            )}
            <div className="row-span-1 col-span-1 flex grow flex-nowrap content-center w-full justify-center ">
              <Link to={"/Veranstaltungen/" + ID + "/" + Date}>
                <CardButton label="Weitere Informationen" fullWidth={false} customClasses="h-[56px]" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
