import React, { Component } from "react";
import "./About.scss";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import TimeLine from '../../components/TimeLine'

class About extends Component {
  state = {
    events: [],
    isLoading: true,
  };

  componentDidMount() {
    axios
      .get(AppURL.VerticalTimeline)
      .then((response) => {
        this.setState({ events: response.data, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { events, isLoading } = this.state;

    if (isLoading) {
      return <div>Lade Daten..</div>;
    }

    if (!events.length) {
      return <div>Hier entsteht die Historie der Meistersingerhalle.</div>;
    }

    return (
      <div className="py-16 bg-white">
        <div className="sm:container m-auto text-gray-600 md:px-12 xl:px-6">
          <TimeLine events={events} />
        </div>
      </div>
    );
  }
}

export default About;
