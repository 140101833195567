import React from "react";
import backgroundImage from "../../assets/MSH_Außenansicht_Daemmerung.jpg";

function Impressum() {
  return (
    <div>
        <section
          className="text-white py-40 lg:h-1/2 lg:items-center bg-cover bg-center"
          style={{
            backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.1), rgba(128, 128, 128, 0.3)), url(${backgroundImage})`,
          }}
        >
          <div className="mx-auto max-w-screen-xl px-4">
            <div className="mx-auto max-w-3xl text-center">
              <h1 className="text-white bg-gradient-to-r from-red-300 via-red-500 to-gray-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
                Impressum
              </h1>
              <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
                Hier finden Sie das Impressum der Meistersingerhalle
              </p>
            </div>
          </div>
        </section>
      <section className="bg-gray-100 py-8 flex-grow">
        <div className="sm:container mx-auto grid md:grid-cols-3 gap-8">
          <div className="mt-8 md:col-start-2">
        <h2 className="text-2xl font-bold mb-4">Herausgegeben von</h2>
        <p>(nach § 5 Abs. 1 TMG; § 55 Abs. 1 RStV) :</p>
        <p>Stadt Nürnberg</p>
        <p>Meistersingerhalle</p>
        <p>Münchener Straße 21</p>
        <p>90478 Nürnberg</p>
        <p>Telefon : 0911 / 231 - 80 00</p>
        <p>Telefax : 0911 / 231 - 80 16</p>
        <p><a href="/contact" className="text-red-500 hover:underline">Kontaktformular</a></p>
        <p>Vertretungsberechtigt (nach § 5 Abs. 1 TMG; § 55 Abs. 1 RStV) :</p>
        <p>Oberbürgermeister Marcus König</p>
        <p>Inhaltliche Verantwortung (nach § 55 Abs. 2 RStV) :</p>
        <p>Katharina Puff-Schwartz</p>
        <p>Geschäftsleitung der Meistersingerhalle</p>
        <p>Umsatzsteueridentifikationsnummer (nach § 27 a UstG):</p>
        <p>DE 133 552 578</p>
        <p>Zentrale elektronische Kontaktadressen:</p>
        <p>DE-Mail: poststelle[at]stadt.nuernberg.de-mail.de</p>
        <p>Verbraucherstreitbeilegungsgesetz (VSBG):</p>
        <p><a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank" rel="noreferrer" className="text-red-500 hover:underline">Plattform der EU-Kommission zur Online-Streitbeilegung (OS)</a></p>
        <p><a href="https://www.nuernberg.de/internet/stadtportal/haftung_fuer_inhalte.html" target="_blank" rel="noreferrer" className="text-red-500 hover:underline">Hinweise zur Haftung für Inhalte und Links</a></p>
        <p><a href="https://www.nuernberg.de/internet/stadtportal/datenschutz.html" target="_blank" rel="noreferrer" className="text-red-500 hover:underline">Hinweise zum Datenschutz</a></p>
        <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
      </div>

        </div>
      </section>
    </div>
  );
}

export default Impressum;
