import React, { Component } from "react";
import "./News.scss";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import HeadingParagraph from "../../components/Texts/HeadingParagraph";
import NewsCard from '../../components/NewsCard'

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsData: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }
  fetchData = async () => {
    try {
      const response = await axios.get(
        `${AppURL.NewsList}?page=${this.state.currentPage}`
      );
      this.setState({
        newsData: response.data.data,
        currentPage: response.data.current_page,
        totalPages: response.data.last_page,
      });
    } catch (error) {
      console.error("Error fetching news data:", error);
    }
  };
  formatDate = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  render() {
    const { newsData } = this.state;

    return (
      <section className="text-gray-600 body-font sm:mx-12 flex justify-center sm:rounded-xl">
        <div className="sm:container py-8 mx-auto">
          <HeadingParagraph text="Neuigkeiten" />
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full mt-8">
            {newsData.map((item) => (
              <NewsCard item={item} key={item.id} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default News;
