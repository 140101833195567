import React from 'react'
import { ReactComponent as DownloadSvg } from '../assets/icons/arrow-down-tray.svg'

const DownloadSection = ({ config }) => {
  return (
    <div className="h-auto w-full space-y-8 flex flex-col justify-center sm:container mx-auto">
      <div className="text-center">
        <span className="text-lg font-bold">{ config.heading }</span>
      </div>

      <div className="mx-auto w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-8 md:grid-rows-4 lg:grid-rows-3 mt-3">
        {config.links.map((link, index) => (
          <a
            key={index}
            href={link.url}
            // download={link.fileName}
            target="_blank"
            rel="noreferrer"
            className="flex justify-start h-8 pl-8 md:pl-0"
          >
            <button className="cursor-pointer hover:text-gray-700 flex flex-row space-x-1">
              <DownloadSvg className="w-5 h-5" />
              <span className="text-left">{link.text}</span>
            </button>
          </a>
        ))}
      </div>
    </div>
  );
};

export default DownloadSection
