import React from "react"
import DropDownItem from './DropDownItem'

export default function DropDown({array, isSubDropdown = false}) {
  if (array === undefined) return <></>
  return (
    <>
      <div
        className={`z-10 origin-top-right text-white backdrop-blur-sm focus:outline-none bg-primary-2 rounded-lg ${isSubDropdown ? 'w-[220px]': 'w-[179px]'}`}
        tabIndex="1"
      >
        {array.map((item, index) => (
          <div
            className={`whitespace-nowrap hover:bg-primary-2 hover:text-white py-2 ${
              index === 0 ? 'rounded-t-lg' : ''} ${index === array.length - 1 ? 'rounded-b-lg' : ''}`}
            key={`${index}-${item.title}`}
          >
            <DropDownItem item={item} />
          </div>
        ))}
      </div>
    </>
  )
}
