import React from "react";
import "./Footer.scss";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import FooterLink from "./FooterLink";
import FooterHeading from "./FooterHeading";

const Footer = () => {
  return (
    <footer
      className="bg-primary-2 text-white"
    >
      <div className="bg__upper grid grid-cols-1 md:grid-cols-4 gap-8 py-8 px-20">
        <div>
          <FooterHeading text="Meistersingerhalle Quicklinks" />
          <ul>
            <FooterLink text="Über" link="/historie" />
            <FooterLink
              text="Karriere"
              link="https://www.nuernberg.de/internet/arbeitgeberin/"
            />
            <FooterLink text="Kontakt" link="/contact" />
            <FooterLink text="Sitemap" link="/sitemap" />
          </ul>
        </div>
        <div>
          <FooterHeading text="Rechtliches" />
          <ul>
            <FooterLink text="Impressum" link="/Impressum" />
            <FooterLink
              text="Datenschutzerklärung"
              link="https://www.nuernberg.de/internet/stadtportal/datenschutz.html"
            />
            <FooterLink text="Urheberrecht" link="/Urheberrecht" />
          </ul>
        </div>
        <div>
          <FooterHeading text="Social Media" />
          <ul>
            <FooterLink
              text="Facebook"
              link="https://www.facebook.com/pages/Meistersingerhalle%20Nürnberg/179391155427601/"
            />
            <FooterLink
              text="Instagram"
              link="https://www.instagram.com/meistersingerhalle/?hl=de"
            />
          </ul>
          
        </div>
        <div>
          <FooterHeading text="Kontakt"/>
          <ul>
          <a href="tel:+499112318000">
          <FooterLink text="Tel: 0911 2318000" target="_blank" rel="noopener noreferrer" />
          </a>
          <a href="mailto:meistersingerhalle@stadt.nuernberg.de">
          <FooterLink text="E-Mail: meistersingerhalle@stadt.nuernberg.de" target="_blank" rel="noopener noreferrer" />
          </a>
          </ul>
        </div>
            
        

        
        <div className="md:text-center flex items-center justify-center"></div>
      </div>
      <hr className="border-t border-white mt-4" />
      <div className="py-6 px-4 md:flex md:items-center md:justify-between">
        <span className="text-sm sm:text-center block">
          © {new Date().getFullYear()}{" "}
          <a href="/">Meistersingerhalle Nürnberg</a>. Alle Rechte vorbehalten.
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center md:mt-0">
          <a
            href="https://www.facebook.com/pages/Meistersingerhalle%20Nürnberg/179391155427601/"
            className="text-white-400 hover:text-white-900 dark:hover:text-white"
          >
            <FaFacebook className="w-5 h-5" /> {/* Use the FaFacebook icon */}
            <span className="sr-only">Facebook</span>
          </a>
          <a
            href="https://www.instagram.com/meistersingerhalle/?hl=de"
            className="text-white-400 hover:text-white-900 dark:hover:text-white"
          >
            <FaInstagram className="w-5 h-5" /> {/* Use the FaInstagram icon */}
            <span className="sr-only">Instagram</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
