import React, { Component } from "react";
import KleinerSaalContainer from "../../../container/Halle/KleinerSaal/KleinerSaal";
import axios from "axios";
import AppURL from "../../../api/AppUrl";
import PageSlider from '../../../components/PageSlider'

class KleinerSaal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      KleinerSliderData: [],
    };
  }
  componentDidMount() {
    axios
      .get(AppURL.KleinerSaalSlider)
      .then((response) => {
        this.setState({ KleinerSliderData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div>
        <PageSlider data={this.state.KleinerSliderData} />
        <KleinerSaalContainer formType="formKlein" />
      </div>
    );
  }
}

export default KleinerSaal;
