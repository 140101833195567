import React from "react";
import CardButton from "./CardButton";

const Card = ({ card }) => {
  return (
    <div className="w-full h-full">
      <div className="flex flex-col center sm:rounded-xl bg-white shadow-md overflow-hidden h-full">
        <img
          src={card.image}
          alt={card.heading}
          className="w-full h-full object-cover"
        />
        <h4 className="text-center font-semibold text-xl text-dark my-2">{card.heading}</h4>
        <p className="text-body-color mx-1 text-center">{card.paragraph}</p>
        {card.button_link && card.button_text && (
          <>
            <div className="flex-grow h-8" />
            <a href={card.button_link} className="flex mb-4 justify-center items-center w-full">
              <CardButton label={card.button_text} />
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
