import React from 'react'
import { Chrono } from 'react-chrono'

const TimeLine = ({ events }) => {
  function isYoutubeLink(url) {
    const youtubePatterns = [
      'youtube.com',
      'youtu.be',
      'youtube-nocookie.com',
      'youtube.googleapis.com',
    ]

    return youtubePatterns.some(pattern => url.includes(pattern))
  }

  const sortedItems = events.sort((a, b) => b.year - a.year)
  const items = sortedItems.map(({ year, headline, paragraph, image }) => {
    const mediaType = isYoutubeLink(image) ? 'VIDEO' : 'IMAGE'

    return {
      title: year,
      cardTitle: headline,
      cardDetailedText: paragraph,
      media: {
        type: mediaType,
        source: {
          url: image,
        },
      },
    }
  })

  const theme = {
    primary: '#006eb8',
    secondary: 'white',
    cardBgColor: 'white',
    titleColor: 'black',
    titleColorActive: 'black',
    cardMediaBgColor: 'white'
  }

  const fontSizes = {
    title: '2rem',
  }

  return (
    <>
      <div className="w-full h-full block md:hidden">
        <Chrono
          items={items}
          mode="VERTICAL"
          disableNavOnKey
          hideControls
          useReadMore={false}
          theme={theme}
          fontSizes={fontSizes}
        />
      </div>

      <div className="w-full h-full hidden md:block">
        <Chrono
          items={items}
          mode="VERTICAL_ALTERNATING"
          disableNavOnKey
          hideControls
          useReadMore={false}
          theme={theme}
          fontSizes={fontSizes}
        />
      </div>
    </>
  )
}

export default TimeLine