import Moment from "react-moment";
import { Link } from "react-router-dom";
import React from "react";
import CardButton from "../components/CardButton";

const ProgramCards = ({ data }) => {
  function getDate(item) {
    const dateName = Object.getOwnPropertyNames(item.DATUM[0])[0];
    return dateName;
  }

  return (
    <div className="w-full h-full flex justify-center">
      {data.VERANSTALTUNGEN.map((item) => (
        <div
          key={item.VERANSTALTUNGID}
          className="max-w-lg overflow-hidden rounded-xl bg-white shadow-md"
        >
          <img
            src={item.BILD[0].sn + "?groesse=2"}
            alt="Veranstaltungsbild Meistersingerhalle"
            className="object-cover card--image w-full rounded-t-2xl"
            style={{ height: "300px", width: "400px" }}
          />
          <div className="px-5 pb-3 pt-3">
            <p className="event--titel font-bold text-large mb-5 text-gray-700 text-center">
              {item.TITEL}
            </p>
            <p className="text-medium text-gray-700 text-center">
              <Moment
                locale="DE"
                date={getDate(item)}
                format="dddd, DD. MMMM YYYY"
              />
            </p>
            <p className="text-medium text-gray-700 mb-5 text-center">
              Beginn {" "}
              <Moment locale="DE" date={getDate(item)} format="HH:mm" />
              {" "}Uhr
            </p>
            <Link
              to={`/Veranstaltungen/${item.VERANSTALTUNGID}/${getDate(item)}`}
              className="mb-3"
            >
              <CardButton label="Zur Veranstaltung" fullWidth={true} />
            </Link>
          </div>
        </div>
      ))}
    </div>

  );
};

export default ProgramCards;
