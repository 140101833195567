import React, { Component } from "react";
import GroßerSaalContainer from "../../../container/Halle/GroßerSaal/GroßerSaal";
import axios from "axios";
import AppURL from "../../../api/AppUrl";
import PageSlider from '../../../components/PageSlider'

class GroßerSaal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GroßerSliderData: [],
    }
  }
  componentDidMount() {
    axios.get(AppURL.GroßerSaalSlider)
    .then(response => {
      this.setState({ GroßerSliderData: response.data });
    }) 
    .catch(error => {
      console.log(error);
    });
  }
  render() {
    return (
      <div>
        <PageSlider data={this.state.GroßerSliderData} />
        <GroßerSaalContainer formType="formGroß"/>
      </div>
    );
  }
}

export default GroßerSaal;
