import React from 'react'

const ContactCard = ({ card, index }) => {
  return (
    <div className="flex flex-col justify-between rounded-none sm:rounded-xl bg-white shadow-md overflow-hidden" key={index}>
      <img
        className="aspect-[4/3] object-cover"
        src={card.image}
        alt={card.name}
      />
      <p className="font-semibold text-lg lg:text-xl text-dark mt-4 text-center mx-2">
        {card.name}
      </p>
      <p className="text-black text-sm text-center mt-2 mx-2">{card.position}</p>
      <div className="flex-grow" />
      <div className="flex flex-row justify-center space-x-2 my-2">
        <i className="fa fa-phone my-auto" />
        <span className="text-body-color">
          {card.contact}
        </span>
      </div>

    </div>
  )
}

export default ContactCard
