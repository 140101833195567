import React, { Component } from "react";
import "./Cards.scss";
import { images } from "../../../constants";
import { Link } from "react-router-dom";
import axios from "axios";
import AppURL from "../../../api/AppUrl";
import SliderLoading from "../../../components/PlaceHolder/SliderLoading";
import { Fragment } from "react";
import CardButton from "../../../components/CardButton";
import HighlightParagraph from '../../../components/Texts/HighlightParagraph'
import HeadingParagraph from '../../../components/Texts/HeadingParagraph'
import Paragraph from '../../../components/Texts/Paragraph'
import HalleCard from '../../../components/Cards/HalleCard'

class Cards extends Component {
  constructor() {
    super();
    this.state = {
      Heading_One: "",
      Heading_Two: "",
      Heading_Paragraph: "",
      Button_Text: "",
      Card_One_Heading: "",
      Card_One_Paragraph: "",
      Card_One_Button: "",
      Card_Two_Heading: "",
      Card_Two_Paragraph: "",
      Card_Two_Button: "",
      Card_Three_Heading: "",
      Card_Three_Paragraph: "",
      Card_Three_Button: "",
      isLoading: "",
      mainDiv: "d-none",
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.LandingPageInfo)
      .then((response) => {
        let StatusCode = response.status;
        if (StatusCode === 200) {
          let JsonData = response.data[0];
          this.setState({
            Heading_One: JsonData["Heading_One"],
            isLoading: "d-none",
            mainDiv: "",
          });
          this.setState({ Heading_Two: JsonData["Heading_Two"] });
          this.setState({ Heading_Paragraph: JsonData["Heading_Paragraph"] });
          this.setState({ Button_Text: JsonData["Button_Text"] });
          this.setState({ Card_One_Heading: JsonData["Card_One_Heading"] });
          this.setState({ Card_One_Paragraph: JsonData["Card_One_Paragraph"] });
          this.setState({ Card_One_Button: JsonData["Card_One_Button"] });
          this.setState({ Card_Two_Heading: JsonData["Card_Two_Heading"] });
          this.setState({ Card_Two_Paragraph: JsonData["Card_Two_Paragraph"] });
          this.setState({ Card_Two_Button: JsonData["Card_Two_Button"] });
          this.setState({ Card_Three_Heading: JsonData["Card_Three_Heading"] });
          this.setState({
            Card_Three_Paragraph: JsonData["Card_Three_Paragraph"],
          });
          this.setState({ Card_Three_Button: JsonData["Card_Three_Button"] });
        }
      })
      .catch((error) => {});
  }

  render() {
    return (
      <Fragment>
        <SliderLoading isLoading={this.state.isLoading} />
        <div className={this.state.mainDiv}>
          <div className="text-center py-8 bg-white">
            <section className="sm:mx-12">
              <div className="sm:container sm:mx-auto">
                <div className="flex flex-wrap justify-center">
                  <div className="flex flex-col space-y-4 w-full px-4 text-center justify-center mb-8">
                    <HighlightParagraph text={this.state.Heading_One} />
                    <HeadingParagraph text={this.state.Heading_Two} />
                    <Paragraph text={this.state.Heading_Paragraph} />
                    <Link to="/Halle">
                      <CardButton label={this.state.Button_Text} />
                    </Link>
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full h-full">
                  <HalleCard
                    imageSrc={images.MSH_GroßerSaal_2}
                    heading={this.state.Card_One_Heading}
                    paragraph={this.state.Card_One_Paragraph}
                    linkTo="/Halle/GrosserSaal"
                    buttonLabel={this.state.Card_One_Button}
                  />

                  <HalleCard
                    imageSrc={images.MSH_KleinerSaal}
                    heading={this.state.Card_Two_Heading}
                    paragraph={this.state.Card_Two_Paragraph}
                    linkTo="/Halle/KleinerSaal"
                    buttonLabel={this.state.Card_Two_Button}
                  />

                  <HalleCard
                    imageSrc={images.MSH_Konferenzraum}
                    heading={this.state.Card_Three_Heading}
                    paragraph={this.state.Card_Three_Paragraph}
                    linkTo="/Halle/Konferenzraum"
                    buttonLabel={this.state.Card_Three_Button}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Cards;
