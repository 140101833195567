import React, { Component } from "react";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import { Link } from "react-router-dom";
import HeadingParagraph from "../../components/Texts/HeadingParagraph";
import CardButton from '../../components/CardButton'

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsData: [],
      currentPage: 1,
      totalPages: 1,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const response = await axios.get(
        `${AppURL.NewsList}?page=${this.state.currentPage}`
      );
      this.setState({
        newsData: response.data.data,
        currentPage: response.data.current_page,
        totalPages: response.data.last_page,
      });
    } catch (error) {
      console.error("Error fetching news data:", error);
    }
  };

  goToPage = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchData();
    });
  };

  formatDate = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  render() {
    const { newsData, currentPage, totalPages } = this.state;

    return (
      <div className="bg-white">
        <div className="sm:container mx-auto">
          <div className="py-8 sm:rounded-xl">
            <HeadingParagraph text="Neuigkeiten" />
            <div className="sm:container my-12 mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 h-full w-full justify-center">
                {newsData.map((item) => (
                  <div
                    className="flex flex-col w-full h-full"
                    key={item.id}
                  >
                    <article className="flex flex-col overflow-hidden sm:rounded-xl shadow-lg bg-white h-full">
                      <img
                        alt="Placeholder"
                        className="block h-auto w-full"
                        src={item.news_image}
                      />

                      <header className="flex flex-col items-center justify-between leading-tight p-2 md:p-4">
                        <h1 className="text-xl font-bold text-center">{item.news_title}</h1>
                        <p className="text-grey-darker text-sm break-words">
                          {this.formatDate(item.date)}
                        </p>
                      </header>
                      <div className="h-16 overflow-y-hidden px-2">
                        <p className="text-grey-darker text-sm break-words text-body-color text-center">
                          {item.news_text.length > 80
                            ? `${item.news_text.slice(0, 80)}...`
                            : item.news_text}
                        </p>
                      </div>
                      <div className="flex-grow" />
                      <div className="text-center py-4">
                        <Link
                          to={{
                            pathname: `/news/${item.news_code}`,
                            state: { newsItem: item },
                          }}
                        >
                          <CardButton label="Weiterlesen" type="button" />
                        </Link>
                      </div>
                    </article>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className="flex items-center justify-center mt-8">
                <nav className="inline-flex">
                  {currentPage > 1 && (
                    <a
                      href="#"
                      className="bg-white text-black hover:bg-red-500 hover:text-white py-2 px-4 rounded-xl"
                      onClick={() => this.goToPage(currentPage - 1)}
                    >
                      <svg
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.707 5.293a1 1 0 010 1.414L8.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </a>
                  )}

                  {[...Array(totalPages)].map((_, index) => (
                    <a
                      key={index + 1}
                      href="#"
                      className={`bg-white text-black hover:bg-red-500 hover:text-white py-2 px-4 ${
                        currentPage === index + 1 ? "font-bold" : ""
                      }`}
                      onClick={() => this.goToPage(index + 1)}
                    >
                      {index + 1}
                    </a>
                  ))}

                  {currentPage < totalPages && (
                    <a
                      href="#"
                      className="bg-white text-black hover:bg-red-500 hover:text-white py-2 px-4 rounded-r"
                      onClick={() => this.goToPage(currentPage + 1)}
                    >
                      <svg
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.293 14.707a1 1 0 010-1.414L11.586 10 8.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </a>
                  )}
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>

    );
  }
}

export default News;
