import React, { useState, useEffect } from "react";
import "./Events.scss";
import EventCard from "../../components/EventCard";
import axios from "axios";
import "./Spinner.scss";
import Pagination from "../../components/Pagination/Pagination";
import { FaArrowRight, FaArrowLeft, FaCalendarDay } from "react-icons/fa";
import SearchBox from "./SearchBox";

// Doku: https://vk.nuernberg.de/dokus/Exportschnittstelle/
// Prod: https://vk.nuernberg.de/export.php
// Dev:  https://vk2.amon.de/export.php
// Notwendige Parameter: TYP=SUCHE&FORMAT=JSON&START_DATUM=[aktuelles Datum im YYYY-MM-DD Format]

const Events = () => {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(12);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [searchTerm, setSearchTerm] = useState("");

  const monthsInGerman = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  //Get indexes
  var indexOfLastPost = currentPage * postsPerPage;
  var indexOfFirstPost = indexOfLastPost - postsPerPage;

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  //vk-nürnberg ist in package-settings als proxy gesetzt
  var url = "";

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");

    let formattedDate;

    if (year === currentYear && month === currentMonth) {
      formattedDate = `${currentYear}-${mm}-${dd}T06:00`;
    } else {
      const formattedMonth = String(month + 1).padStart(2, "0");
      formattedDate = `${year}-${formattedMonth}-01T06:00`;
    }

    updateUrl(formattedDate, indexOfFirstPost, postsPerPage);
    fetchData();
  }, [year, month]);

  const handleMonthChange = (direction) => {
    if (direction === "left") {
      if (month === 0) {
        setMonth(11);
        setYear(year - 1);
        setCurrentPage(1);
      } else {
        setMonth(month - 1);
        setCurrentPage(1);
      }
    } else {
      if (month === 11) {
        setMonth(0);
        setYear(year + 1);
        setCurrentPage(1);
      } else {
        setMonth(month + 1);
        setCurrentPage(1);
      }
    }
  };

  const handleYearChange = (direction) => {
    if (direction === "left" && year > new Date().getFullYear()) {
      setYear(year - 1);
      setCurrentPage(1);
    } else if (direction === "right") {
      setYear(year + 1);
      setCurrentPage(1);
    }
  };

  function updateUrl(today, indexOfFirstPost, postsPerPage) {
    url =
      "https://vk.nuernberg.de/export.php?" +
      "USERNAME=meistersingerhalle&password=Bierstube" +
      "&TYP=SUCHE&FORMAT=JSON&" +
      "&START_DATUM=" +
      today +
      "&AUSGABEFELDER=TITEL,KURZTITEL,UNTERTITEL,BESCHREIBUNG,INTERNETSEITE,FACEBOOK,TWITTER,VIDEO," +
      "VVKLINK,VVKDATUM,VVKUEBLICH,VVKINFOS,KINDER,FAMILIEN,INTERKULTUR,ALTER_VON,ALTER_BIS," +
      "LETZTEAENDERUNG,ORT,VERANSTALTER,DATUM,ERSTERTERMIN,KATEGORIE,ART,BILD,BILDER,PREISE,TAGS,PRIVATETAGS,LINKS" +
      "&START_LIMIT=" +
      indexOfFirstPost +
      "&ALLETERMINE=1" +
      "&ALLEUHRZEITEN=1" +
      "&KOMPLETTVERANSTALTER=" +
      1 +
      "&KOMPLETTVERANSTALTUNGSORT=" +
      1 +
      "&MAX_LIMIT=" +
      postsPerPage;
  }

  const fetchData = async () => {
    const result = await axios(url);
    setData(result.data);
  };

  const paginate = async (pageNumber) => {
    setCurrentPage(pageNumber);
    indexOfLastPost = pageNumber * postsPerPage;
    indexOfFirstPost = indexOfLastPost - postsPerPage;
    updateUrl(today, indexOfFirstPost, postsPerPage);
    setData(null);
    await fetchData();
    goToTop();
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearchSubmit = async (input) => {
    console.log("Received search input:", input); // Confirm input is received

    const today = new Date().toISOString().split("T")[0]; // Assuming 'today' is not defined
    const encodedInput = encodeURIComponent(input); // Ensure the input is URL-safe

    url =
      "https://vk.nuernberg.de/export.php?" +
      "USERNAME=meistersingerhalle&password=Bierstube" +
      "&TYP=SUCHE&FORMAT=JSON&" +
      "&START_DATUM=" +
      today +
      "&AUSGABEFELDER=TITEL,KURZTITEL,UNTERTITEL,BESCHREIBUNG,INTERNETSEITE,FACEBOOK,TWITTER,VIDEO," +
      "VVKLINK,VVKDATUM,VVKUEBLICH,VVKINFOS,KINDER,FAMILIEN,INTERKULTUR,ALTER_VON,ALTER_BIS," +
      "LETZTEAENDERUNG,ORT,VERANSTALTER,DATUM,ERSTERTERMIN,KATEGORIE,ART,BILD,BILDER,PREISE,TAGS,PRIVATETAGS,LINKS" +
      "&START_LIMIT=" +
      indexOfFirstPost +
      "&ALLETERMINE=1" +
      "&ALLEUHRZEITEN=1" +
      "&KOMPLETTVERANSTALTER=1" +
      "&KOMPLETTVERANSTALTUNGSORT=1" +
      "&MAX_LIMIT=" +
      postsPerPage +
      "&SUCHBEGRIFF=" +
      encodedInput;

    console.log("Searching with URL:", url); 
    const result = await axios(url);
    setData(result.data);
    console.log("Data received:", result.data);
    setCurrentPage(1);
  };

  return (
    <>
      {data != null && (
        <>
          <div className="container md:px-5 py-7 md:mx-auto flex justify-center items-center space-x-4 text-white text-xl">
            {/* Year Buttons */}
            <div className="bg-primary-2 text-white font-bold py-2 px-6 shadow-lg rounded flex items-center">
              <button onClick={() => handleYearChange("left")} className="">
                <FaArrowLeft className="mr-2 hover:scale-125 duration-150 ease-in-out" />{" "}
                {/* Icon for previous year */}
              </button>
              <FaCalendarDay className="mr-2" /> {/* Example icon for year */}
              {year}
              <button onClick={() => handleYearChange("right")} className="">
                <FaArrowRight className="ml-2 hover:scale-125 duration-150 ease-in-out" />{" "}
                {/* Icon for next year */}
              </button>
            </div>

            <div className="flex-grow mx-4">
              <SearchBox onSearch={handleSearchSubmit} />
            </div>

            {/* Month Buttons */}
            <div className="bg-primary-2 text-white font-bold py-2 px-6 shadow-lg rounded flex items-center">
              <button onClick={() => handleMonthChange("left")} className="">
                <FaArrowLeft className="mr-2 hover:scale-125 duration-150 ease-in-out" />{" "}
                {/* Icon for previous month */}
              </button>

              <span className="rounded">{monthsInGerman[month]}</span>

              <button onClick={() => handleMonthChange("right")} className="">
                <FaArrowRight className="ml-2 hover:scale-125 duration-150 ease-in-out" />{" "}
                {/* Icon for next month */}
              </button>
            </div>
          </div>

          <div className="sm:container md:px-5 pb-14 mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8">
              {data?.VERANSTALTUNGEN?.map((item, index) => (
                <div
                  className="h-full"
                  key={`${item.VERANSTALTUNGID}-${index}`}
                >
                  <EventCard
                    Title={item.TITEL}
                    Image={item.BILD[0]?.sn + "?groesse=2"}
                    Location={item.ORTSNAMEZUSATZ}
                    Date={GetDate(item)}
                    Entry={GetTime(item)}
                    Description={item.BESCHREIBUNG}
                    Subtitle={item.UNTERTITEL}
                    Links={item.LINKS}
                    VVKlink={item.VVKLINK}
                    ID={item.VERANSTALTUNGID}
                    IsCancelled={item.ABGESAGT}
                  />
                </div>
              )) || <p>Keine Veranstaltungen gefunden.</p>}
            </div>

            <div className="container px-5 py-14 mx-auto flex justify-center">
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={data.ANZAHLGESAMT}
                paginate={paginate}
                currentPage={currentPage}
              ></Pagination>
            </div>
          </div>
        </>
      )}
      {data == null && (
        <div className="container px-5 pt-24 pb-80 mx-auto">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Events;

function GetDate(item) {
  var dateName;
  dateName = Object.getOwnPropertyNames(item.ALLETERMINE)[0];
  return dateName;
}

function GetTime(item) {
  var dateName;
  dateName = Object.getOwnPropertyNames(item.ALLETERMINE)[0];
  dateName = dateName.split("T")[1];
  if (
    Object.values(item.ALLETERMINE)[0].EIN === undefined ||
    Object.values(item.ALLETERMINE)[0].EIN === null ||
    Object.values(item.ALLETERMINE)[0].length === 0
  ) {
    return dateName;
  }
  return Object.values(item.ALLETERMINE)[0].EIN;
}
