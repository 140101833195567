import React from 'react'

const AnreiseCard = ({ headline, street, city, vgn, geoportal }) => {
  return (
    <div className="flex flex-col border w-auto bg-white rounded-lg shadow-md p-4">
      <h3 className="text-lg font-semibold text-center">
        { headline }
      </h3>
      <p className="text-center mt-1">{ street }</p>
      <p className="text-center">{ city }</p>
      <a
        href={ vgn }
        target="_blank"
        rel="noreferrer"
        className="text-primary-1 hover:underline text-center mt-1"
      >
        Öffentliche Verkehrsmittel
      </a>
      <a
        href={ geoportal }
        target="_blank"
        rel="noreferrer"
        className="text-primary-1 hover:underline text-center"
      >
        Stadtplan
      </a>
    </div>
  )

}

export default AnreiseCard
