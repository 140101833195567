import React, { Component } from "react";
import "./Halle.scss";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import InfoColumns from "../../components/InfoColumns/InfoColumns";
import ImageSliderWithText from "../../components/HallSmallSlider/ImageSliderWithTextLeft";

class Halle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      HalleData: [],
      HallePDFData: [],
      GoodToKnowData: [],
      SmallSliderData: [],
      expandedCategory: null,
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.GoodToKnow("hallen"))
      .then((response) => {
        this.setState({ GoodToKnowData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(AppURL.HalleInfo)
      .then((response) => {
        this.setState({ HalleData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(AppURL.HallePDF)
      .then((response) => {
        this.setState({ HallePDFData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(AppURL.SmallSlider("hallen"))
      .then((response) => {
        this.setState({ SmallSliderData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    link.setAttribute("target", "_blank");
    link.click();
  };

  handleAccordionChange = (category) => {
    this.setState((prevState) => ({
      expandedCategory:
        prevState.expandedCategory === category ? null : category,
    }));
  };

  render() {
    const { HalleData, HallePDFData, expandedCategory } = this.state;

    // Group the PDF data by category
    const groupedPDFData = HallePDFData.reduce((groups, item) => {
      const category = item.category;
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(item);
      return groups;
    }, {});

    return (
      <div>
        <section className="bg-white">
          <div className="container px-6 py-10 mx-auto">
            {/* InfoData Section */}
            {/*             <div className="my-6 p-6">
              {HalleData.map((data) => (
                <div key={data.id}>
                  <span className="font-semibold text-lg text-red mb-1 block">
                    {data.heading}
                  </span>
                  <h2 className="font-bold text-3xl sm:text-4xl md:text-[40px] text-dark mb-4">
                    {data.subtitle}
                  </h2>
                  <p className="text-base text-body-color">{data.paragraph}</p>
                </div>
              ))}
            </div> */}
            {/* ImageSliderWithText Section */}
            {/*             <div className="my-6 p-6 bg-white shadow-lg rounded-lg">
              {this.state.isLoading ? (
                <p>Lade...</p> // You can replace this with a spinner or any loading indicator
              ) : (
                <ImageSliderWithText
                  slides={(this.state.SmallSliderData ?? []).map((item) => ({
                    image: item.slider_image,
                    text: item.slider_paragraph,
                  }))}
                  className="mt-6"
                />
              )}
            </div> */}
          </div>
        </section>
      </div>
    );
  }
}

export default Halle;
