import React from 'react'

const FooterHeading = ({ text }) => {
  return (
    <h2 className="underline mb-6 text-sm font-semibold text-white-500 uppercase text-left">
      { text }
    </h2>
  )
}

export default FooterHeading
