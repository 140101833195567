export const menuNames = [
  {
    title: "Startseite",
    link: "/",
    activeClassName: "active",
  },
  {
    title: "Besuch",
    link: "/Besuch",
    activeClassName: "active",
  },
  {
    title: "Veranstaltungen",
    link: "/Veranstaltungen",
    activeClassName: "active",
  },
  {
    title: "Veranstalter",
    link: "/Veranstalter",
    activeClassName: "active",
  },
  {
    title: "Halle",
    link: "/Halle",
    activeClassName: "active",
    subLink: [
      { title: "Großer Saal", link: "/Halle/GrosserSaal" },
      { title: "Kleiner Saal", link: "/Halle/KleinerSaal" },
      {
        title: "Konferenzräume",
        link: "/Halle/Konferenzraum",
        activeClassName: "active",
        subLink: [
          { title: "Konferenzsaal 1", link: "/Halle/Konferenzsaal1" },
          { title: "Konferenzsaal 2", link: "/Halle/Konferenzsaal2" },
          { title: "Konferenzsaal 3", link: "/Halle/Konferenzsaal3" },
          { title: "Konferenzsaal 4", link: "/Halle/Konferenzsaal4" },
          { title: "Konferenzsaal 5", link: "/Halle/Konferenzsaal5" },
          { title: "Konferenzsaal 6", link: "/Halle/Konferenzsaal6" },
          { title: "Konferenzsaal 7", link: "/Halle/Konferenzsaal7" },
          { title: "Meistersingerstüberl", link: "/Halle/Konferenzsaal8" },
          { title: "Verborgene Kulissen", link: "/Halle/Konferenzsaal9" },
        ],
      },
      { title: "Orgel", link: "/Halle/Orgel" },
      { title: "360 Grad", link: "/Halle/360Grad" },
    ],
  },
  {
    title: "Historie",
    link: "/Historie",
    activeClassName: "active",
  },
  {
    title: "Kontakt",
    link: "/Contact",
    activeClassName: "active",
  },
];
