import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import { images } from "../../constants";
import HeadingParagraph from "../../components/Texts/HeadingParagraph";
import HighlightParagraph from '../../components/Texts/HighlightParagraph'
class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VeranstalterTextData: [],
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.VeranstalterText)
      .then((response) => {
        this.setState({ VeranstalterTextData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { VeranstalterTextData } = this.state;

    return (
      <>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            {VeranstalterTextData.map((item) => (
              <div>
                <div
                  key={item.id}
                  className="text-center mx-auto mb-12 lg:mb-20 w-full space-y-4"
                >
                  <HighlightParagraph text={item.Heading} />
                  <HeadingParagraph text={item.BigText} />
                  {/*                 <img src={images.MSH_Außenansicht_2} /> */}

                </div>

                <p className="text-base text-black mt-8 px-4">{item.Paragraph}</p>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default Services;
