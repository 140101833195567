import React, { useState } from "react";
import "./ImageSliderWithText.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSliderWithText = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0); // Initialize with the first slide

  const settings = {
    infinite: true,
    speed: 1000,
    dots: true,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };
  if (!slides || slides.length === 0) {
    return <p>Keine Slides verfügbar.</p>;
  }
  return (
    <div className="flex flex-wrap md:flex-nowrap mt-8">
      <div className="w-full md:w-1/2 p-4 flex">
        <div className="">
          <span className="text-left">{slides[currentSlide]?.text || ""}</span>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-4">
        <Slider {...settings}>
          {(slides ?? []).map((slide, index) => (
            <div key={index}>
              <img
                src={slide.image}
                alt={`Slide ${index}`}
                className="w-full h-auto rounded-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ImageSliderWithText;
