import React, { Component } from "react";
import { LandingProgram, Header, News, Halle } from "../../container";
import CardsContainer from "../../container/Home/Cards/Cards";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import SliderLoading from "../../components/PlaceHolder/SliderLoading";
import PageSlider from '../../components/PageSlider'

export default class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      SliderData: [],
      isLoading: "",
      mainDiv: "d-none",
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
    this.GetVisitorDetails();
    axios
      .get(AppURL.LandingPageSlider)
      .then((response) => {
        this.setState({
          SliderData: response.data,
          isLoading: "d-none",
          mainDiv: "",
        });
      })
      .catch((error) => {});
  }

  GetVisitorDetails = () => {
    axios.get(AppURL.VisitorDetails).then().catch();
  };

  render() {
    return (
      <div>
        <SliderLoading isLoading={this.state.isLoading} />
        <div className={this.state.mainDiv}>
          <div className="slider-container">
            <PageSlider data={this.state.SliderData} />
          </div>
          <LandingProgram />
          <News />
          <CardsContainer />
          {/* <Halle /> */}
        </div>
      </div>
    );
  }
}
