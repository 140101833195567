import { React } from "react";

const HeadingSmallParagraph = ({ heading, customClasses = "" }) => {
  return (
    <h2
      className={`text-center text-3xl font-bold text-gray-900 ${customClasses}`}
    >
      {heading}
    </h2>
  );
};

export default HeadingSmallParagraph;
