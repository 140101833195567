import React, { Component } from "react";
import "./About.scss";
import About from "../../container/About/About";
import CTAAbout from "../../container/About/CTA/CTA";
import axios from "axios";
import AppURL from "../../api/AppUrl";
import PageSlider from '../../components/PageSlider'

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      HistorieSliderData: [],
    }
  }

  componentDidMount() {
  axios.get(AppURL.HistorieSlider)
  .then(response => {
    this.setState({ HistorieSliderData: response.data });
  }) 
  .catch(error => {
    console.log(error);
  });
}
  render() {
    return (
      <div>
        <PageSlider data={this.state.HistorieSliderData} />
        <About></About>
        <CTAAbout />
      </div>
    );
  }
}

export default AboutPage;