import React from "react";
import { FaLightbulb } from "react-icons/fa"; // Importing the lightbulb icon from react-icons

const InfoColumns = ({ data }) => {
  if (typeof data === "undefined") {
    return;
  }

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 px-8 gap-8">
        <div className="">
          <h2 className="mb-4 font-semibold">{data.list1_heading ?? ""}</h2>
          <ul className="space-y-2">
            {(Array.isArray(data.list1) ? data.list1 : []).map(
              (listItem, listItemIndex) => (
                <li key={listItemIndex}>{listItem}</li>
              )
            )}
          </ul>
        </div>

        <div className="">
          <h2 className="mb-4 font-semibold">{data.list2_heading ?? ""}</h2>
          <ul className="space-y-2">
            {(Array.isArray(data.list2) ? data.list2 : []).map(
              (listItem, listItemIndex) => (
                <li key={listItemIndex}>{listItem}</li>
              )
            )}
          </ul>
        </div>

      <div className="col-span-3 lg:col-span-1">
        <div className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4">
          <FaLightbulb className="text-yellow-500 w-8 h-8" />
          <div className="flex-1">
            <h3 className="mb-4 font-semibold">{data.heading ?? ""}</h3>
            <p className="text-gray-600">{data.paragraph ?? ""}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoColumns;
