import React from "react";
import backgroundImage from "../../assets/MSH_Außenansicht_4.jpg";

function Barrierefreiheit() {
  return (
    <div>
      <section
        className="text-white py-40 lg:h-1/2 lg:items-center bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.1), rgba(128, 128, 128, 0.3)), url(${backgroundImage})`,
        }}
      >
        <div className="mx-auto max-w-screen-xl px-4">
          {/*           <div className="mx-auto max-w-3xl text-center">
            <h1 className="bg-gradient-to-r from-red-300 via-red-500 to-gray-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
              Barrierefreiheit
            </h1>

            <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
              Hier finden Sie alle Informationen zur Barrierefreiheit in der
              Meistersingerhalle
            </p>
          </div> */}
        </div>
      </section>
      <section className="bg-gray-100 py-8">
        <div className="sm:container mx-auto flex justify-center">
          {/* Main Content */}
          <div className="max-w-3xl">
            <h2 className="text-xl font-bold">Anfahrt mit dem Auto</h2>
            <p className="mb-4">
              Vor dem großen Saal wurden vier behindertengerechte Parkplätze
              eingerichtet. Diese liegen direkt vor dem Haupteingang und
              garantieren barrierefreien Zugang zu allen Veranstaltungen.
            </p>

            <p className="mb-4">
              Je zwei weitere Stellplätze befinden sich auf dem Parkplatz an der
              Schultheißallee sowie unmittelbar neben dem Eingang zu den
              Konferenzräumen bzw. zum kleinen Saal.
            </p>

            <p className="mb-4">
              Die Veranstaltungsräume der Meistersingerhalle liegen zum größten
              Teil im Erdgeschoss und sind für Rollstuhlfahrer gut zu erreichen.
            </p>

            <h3 className="text-xl font-semibold mt-4">Rollstuhlplätze</h3>
            <p className="mb-4">
              Bitte beachten Sie beim Kauf von Eintrittskarten, dass
              Rollstuhlplätze auf Grund gesetzlicher Vorschriften nur
              unmittelbar neben den Saaltüren zugelassen sind. Plätze in der
              Saalmitte oder auf dem Rang sind von der Bauordnungsbehörde nicht
              genehmigt. Die Plätze auf dem Rang im großen Saal sowie die
              Konferenzräume 3 und 4 können nicht mit einem Aufzug erreicht
              werden.
            </p>

            <h3 className="text-xl font-semibold mt-4">
              Toiletten für Personen mit Schwerbehinderung
            </h3>
            <p className="mb-4">
              Sowohl im Eingangsbereich des großen Saals als auch des kleinen
              Saals befinden sich behindertengerechte Toiletten.
              Servicemitarbeiter und unser Saal- und Kontrolldienst geben Ihnen
              gerne Auskunft.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Barrierefreiheit;
