import React, { useState, useRef } from "react";
import "./Collapsible.scss";

export default function Collapsible(props) {
  const [isOpen, setOpen] = useState(false);
  const parentRef = useRef();

  return (
    <div className="collapsible">
      <div
        className={isOpen ? "label show" : "label"}
        onClick={() => setOpen(!isOpen)}
      >
        {!isOpen ? props.label : props.labelOpen}
      </div>
      {
        <div
          className="content"
          ref={parentRef}
          style={
            isOpen
              ? { height: parentRef.current.scrollHeight + "px" }
              : { height: "0px" }
          }
        >
          {props.children}
        </div>
      }
    </div>
  );
}
